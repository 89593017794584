import React, { FC, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { AuditorDocument, AuditorsListDocument, useAuditorQuery, useUpdateAuditorMutation } from 'generated/graphql'
import Spinner from 'components/Spinner'
import styles from './styles.module.scss'
import notify from 'utils/notify'
import { Notification } from 'constants/typeConstants'
import { isEmpty } from 'lodash'
import AuditorForm from '../AuditorForm'

const EditAuditor: FC = () => {
  const [defaultValues, setDefaultValues] = useState({})
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const [updateAuditor, { loading: updating }] = useUpdateAuditorMutation()

  const { data, loading } = useAuditorQuery({
    variables: { id: id! },
    onCompleted() {
      setDefaultValues({
        id: data?.auditor?.id,
        name: data?.auditor?.name
      })
    },
    onError() {
      notify(
        Notification.ERROR,
        'There is some error while loading the Auditor data'
      )
    }
  })

  if (loading || isEmpty(defaultValues)) {
    return <Spinner name="circle-notch"/>
  }

  const updateAuditorFunc = (data: any) => {
    updateAuditor({
      variables: { input: { ...data, id } },
      update(cache: any, MutationResult: any) {
        cache.writeQuery({
          query: AuditorDocument,
          variables: {
            id
          },
          data: {
            auditor: {
              ...MutationResult.data?.updateAuditor
            }
          }
        })
      },
      refetchQueries:[{
        query: AuditorsListDocument
      }]
    }).then(() => history.push('/auditors'))
      .catch(() => notify(Notification.ERROR, 'There is some error while updating the auditor'))
  }

  return (
    <div>
      <header className={styles.header}>
        <h2>Auditor: {data?.auditor?.name}</h2>
      </header>
      <AuditorForm
        action={'update'}
        defaultValues={defaultValues}
        onSubmit={updateAuditorFunc}
        loading={updating}
      />
    </div>
  )
}

export default EditAuditor

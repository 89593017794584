import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { InputGroup, Modal, Textarea } from '@wmgtech/legato'

import FormDatepicker from 'components/Form/FormDatepicker'
import FormFooter from 'components/Form/FormFooter/FormFooter'
import styles from './styles.module.scss'

interface IProps {
  show: boolean
  onClose: () => void
  onSubmit: (data: any) => void
}

const OfferModal: FC<IProps> = ({ show, onClose, onSubmit }) => {
  const methods = useForm()
  const { register } = methods
  return (
    <Modal
      className={styles.modal}
      isOpen={show}
      title={
        <div className={styles['modal-title']}>
          <h2>Add New Offer</h2>
        </div>
      }
      onClose={onClose}
      shouldCloseOnOverlayClick
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
          <div className={styles.body}>
            <div className="col-md-6">
              <FormDatepicker
                placeholder="Select a Date"
                name="offerDate"
                label="Offer Date"
                initialDate={null}
                rules={{ required: true }}
                message="Offer date can't be blank"
              />
            </div>
            <InputGroup label="Offer Notes">
              <Textarea
                {...register('notes')}
                name="notes"
                placeholder="Please leave a note regarding this offer"
              />
            </InputGroup>
          </div>
          <FormFooter submit="Create Offer" cancel="Cancel" className="modal" />
        </form>
      </FormProvider>
    </Modal>
  )
}

export default OfferModal

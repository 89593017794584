import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Input, InputGroup } from '@wmgtech/legato'

import FormSelect from 'components/Form/FormSelect'
import LoadingButton from 'components/LoadingButton'
import useData from 'hooks/useData'
import {
  LabelsListQuery,
  useAuditQuery,
  useLabelsListQuery,
} from 'generated/graphql'
import convertToList from 'utils/convertToList'
import styles from './styles.module.scss'

interface ILabelFormProps {
  auditId?: any
  onClose?: () => void
  onSubmit: (data: any) => void
  labelId?: string
}

const AuditLabelForm: FC<ILabelFormProps> = ({
  onClose,
  onSubmit,
  labelId,
  auditId,
}) => {
  const { data: auditData } = useAuditQuery({
    variables: { input: auditId! },
    onCompleted(response) {
      if (labelId) {
        const labelForUpdate = response?.audit?.labels?.find(
          (l) => l?.labelId === labelId
        )
        methods.reset({
          id: labelForUpdate?.id,
          labelId: labelForUpdate?.labelId,
          companyCode: labelForUpdate?.companyCode,
          profitCenter: labelForUpdate?.profitCenter,
        })
      }
    },
  })

  const { data, loading, previousData } = useLabelsListQuery()
  const labelListData = useData<LabelsListQuery>({
    loading,
    previousData,
    data,
  })

  const allLabels = labelListData?.labels?.items
  const takenLabelsIds = auditData?.audit?.labels?.map((l) => l?.labelId)

  //TODO clarify if we need logic to update label name

  if (labelId) {
    takenLabelsIds?.splice(takenLabelsIds?.indexOf(labelId), 1)
  }

  const labelsList = allLabels?.filter((l) => {
    return !takenLabelsIds?.includes(l?.id ?? '')
  })

  const labelList = convertToList(labelsList)

  const methods = useForm()
  const { register } = methods

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
          <div className={styles.body}>
            <InputGroup label="Label Name">
              <input type="hidden" name="id" />
              <FormSelect
                name="labelId"
                list={labelList}
                placeholder="Select a label"
                disabled={!!labelId}
              />
            </InputGroup>
            <InputGroup label="Company Code">
              <Input
                {...register('companyCode')}
                name="companyCode"
                placeholder="Enter Company Code"
              />
            </InputGroup>
            <InputGroup label="Profit Center">
              <Input
                {...register('profitCenter')}
                name="profitCenter"
                placeholder="Enter Profit Center"
              />
            </InputGroup>
          </div>
          <footer className={styles.footer}>
            {onClose && (
              <LoadingButton
                containerStyle={'outline'}
                colorType={'secondary-black'}
                onClick={onClose}
              >
                Cancel
              </LoadingButton>
            )}
            <LoadingButton
              type="submit"
              containerStyle={'outline'}
              colorType={'primary'}
            >
              {labelId ? 'Update Label' : 'Create Label'}
            </LoadingButton>
          </footer>
        </form>
      </FormProvider>
    </>
  )
}

export default AuditLabelForm

import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button } from '@wmgtech/legato'

import { IBreadcrumbs } from 'interfaces/BreadCrumbsInterface'
import Breadcrumbs from 'components/Breadcrumbs'

import {
  AuditDocument,
  useAddAuditActivityCommentMutation,
  useAuditQuery,
  useCreateAuditActivityMutation,
  useDeleteAuditActivityMutation,
  useUpdateAuditActivityMutation,
} from 'generated/graphql'
import SubmissionModal from 'components/SubmissionModal'
import EditActivityModal from '../EditActivityModal/EditActivityModal'
import AddActivityModal from '../AddActivityModal/AddActivityModal'
import convertDateToString from 'utils/convertDateToString'
import notify from 'utils/notify'
import { Notification } from 'constants/typeConstants'

import styles from './styles.module.scss'
import AddActivityCommentModal from '../ActivityComments/AddActivityCommentModal/AddActivityCommentModal'
import classNames from 'classnames'
import ActivitiesTimeline from '../ActivitiesTimeline/ActivitiesTimeline'
import HeaderRowInfo from 'components/HeaderRowInfo/HeaderRowInfo'

interface IActivityPayload {
  typeId: string
  actualDate: string | null
  manuallyProjectedDate: string | null
  projectedDate: string | null
  auditId?: string
}

type DateType = 'actualDate' | 'manuallyProjectedDate' | 'projectedDate'

interface IFilter {
  label: string
  icon: string
  key: DateType
}

const AuditActivitiesHistory: FC = () => {
  const { auditId } = useParams<{
    auditId: string
  }>()

  const breadcrumbs: IBreadcrumbs[] = [
    { name: 'Home', to: '/' },
    { name: 'My Audits', to: '/audits' },
    { name: 'Audit', to: `/audits/${auditId}` },
    { name: 'Activities' },
  ]
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  })

  const [activities, setActivities] = useState<any>([])
  const [isFiltered, setIsFiltered] = useState(false)
  const [selectedActivity, setSelectedActivity] = useState<any>(null)
  const [showCreationModal, setShowCreationModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showCommentModal, setShowCommentModal] = useState(false)
  const [showSubmissionModal, setShowSubmissionModal] = useState(false)
  const [addActivity] = useCreateAuditActivityMutation()
  const [updateActivity] = useUpdateAuditActivityMutation()
  const [deleteActivity] = useDeleteAuditActivityMutation()
  const [addActivityComment] = useAddAuditActivityCommentMutation()

  const toggleCreationModal = useCallback(() => {
    setShowCreationModal((show) => !show)
  }, [])

  const toggleEditModal = useCallback(() => {
    setShowEditModal((show) => !show)
  }, [])

  const toggleSubmissionModal = useCallback(() => {
    setShowSubmissionModal((show) => !show)
  }, [])

  const toggleCommentCreationModal = useCallback(() => {
    setShowCommentModal((show) => !show)
  }, [])

  const { data: auditData } = useAuditQuery({
    variables: { input: auditId! },
  })

  const activitiesData = auditData?.audit?.activities ?? []

  const filters: IFilter[] = [
    {
      label: 'Actual Dates',
      icon: 'calendar',
      key: 'actualDate',
    },
    {
      label: 'Manual Projections',
      icon: 'pencil',
      key: 'manuallyProjectedDate',
    },
    {
      label: 'System Calculated',
      icon: 'calculator',
      key: 'projectedDate',
    },
  ]

  const AddAuditActivity = (data: IActivityPayload) => {
    const input = {
      ...data,
      actualDate: convertDateToString(data.actualDate),
      manuallyProjectedDate: convertDateToString(data.manuallyProjectedDate),
      auditId,
    }
    addActivity({
      variables: { input },
      refetchQueries: [{ query: AuditDocument, variables: { input: auditId } }],
    })
      .then(() => {
        mounted.current && toggleCreationModal()
        notify(Notification.SUCCESS, 'Your activity was saved!')
      })
      .catch(() => {
        notify(
          Notification.ERROR,
          'There is some error while creating audit activity'
        )
      })
  }

  const updateAuditActivity = (data: IActivityPayload) => {
    const input = {
      id: selectedActivity?.id,
      typeId: data.typeId,
      actualDate: convertDateToString(data.actualDate),
      manuallyProjectedDate: convertDateToString(data.manuallyProjectedDate),
    }
    updateActivity({
      variables: { input },
      refetchQueries: [{ query: AuditDocument, variables: { input: auditId } }],
    })
      .then(() => {
        mounted.current && toggleEditModal()
        notify(Notification.SUCCESS, 'Your activity was saved!')
      })
      .catch(() => {
        notify(
          Notification.ERROR,
          'There is some error while updating audit activity'
        )
      })
  }

  const deleteAuditActivity = () => {
    deleteActivity({
      variables: { id: selectedActivity.id },
      refetchQueries: [{ query: AuditDocument, variables: { input: auditId } }],
    })
      .then(() => {
        toggleSubmissionModal()
        notify(Notification.SUCCESS, 'Activity was deleted!')
      })
      .catch(() => {
        notify(
          Notification.ERROR,
          'There is some error while deleting audit activity'
        )
      })
  }

  const AddAuditActivityComment = (data: { comment: string }) => {
    const input = {
      auditActivityId: selectedActivity.id,
      comment: data.comment,
    }
    addActivityComment({
      variables: { input },
      refetchQueries: [{ query: AuditDocument, variables: { input: auditId } }],
    })
      .then(() => {
        mounted.current && toggleCommentCreationModal()
        notify(Notification.SUCCESS, 'Your comment was added!')
      })
      .catch(() => {
        notify(
          Notification.ERROR,
          'There is some error while adding new comment'
        )
      })
  }

  const filterByDateType = (filter: DateType) => {
    const activities = activitiesData.filter((a) => a?.[filter] !== null)
    setIsFiltered(true)
    setActivities(activities)
  }

  return (
    <>
      <AddActivityModal
        show={showCreationModal}
        onClose={toggleCreationModal}
        onSubmit={AddAuditActivity}
        auditId={auditId}
      ></AddActivityModal>
      <EditActivityModal
        show={showEditModal}
        onClose={toggleEditModal}
        onSubmit={updateAuditActivity}
        activity={selectedActivity}
      ></EditActivityModal>
      <SubmissionModal
        name="activity"
        show={showSubmissionModal}
        onClose={toggleSubmissionModal}
        onSubmit={deleteAuditActivity}
      />
      <AddActivityCommentModal
        show={showCommentModal}
        onClose={toggleCommentCreationModal}
        onSubmit={AddAuditActivityComment}
        auditId={auditId}
        activity={selectedActivity}
      ></AddActivityCommentModal>
      <header className={styles.header}>
        <div className="col-lg-5">
          <h2>Audit Activities Home</h2>
          <Breadcrumbs links={breadcrumbs} />
        </div>
        <HeaderRowInfo auditData={auditData} />
      </header>
      <div className={classNames(styles.container, 'row')}>
        <div className="col-md-8">
          <h4>
            Filter On: {'  '}
            <span className={styles.btns}>
              {filters.map((filter) => {
                return (
                  <Button
                    key={filter.key}
                    label={filter.label}
                    containerStyle={'outline'}
                    colorType={'info'}
                    secondaryIcon={filter.icon}
                    size="sm"
                    prefix="fas"
                    onClick={() => filterByDateType(filter.key)}
                  ></Button>
                )
              })}
              <Button
                label="Clear Filters"
                containerStyle={'outline'}
                colorType={'info'}
                secondaryIcon="times"
                size="sm"
                prefix="fas"
                onClick={() => setIsFiltered(false)}
              ></Button>
            </span>
          </h4>
        </div>
        <div className={classNames(styles.align, 'col-md-4')}>
          <Button
            label="New Activity"
            onClick={toggleCreationModal}
            secondaryIcon="plus"
          ></Button>
        </div>

        <hr className="divider" />
        <h2>Activity Timeline</h2>
        <ActivitiesTimeline
          activities={isFiltered ? activities : activitiesData}
          auditId={auditId}
          setSelectedActivity={setSelectedActivity}
          toggleCommentCreationModal={toggleCommentCreationModal}
          toggleEditModal={toggleEditModal}
          toggleSubmissionModal={toggleSubmissionModal}
        ></ActivitiesTimeline>
      </div>
    </>
  )
}

export default AuditActivitiesHistory

import { FC, useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Input } from '@wmgtech/legato'
import classNames from 'classnames'

import currencyFormat from 'utils/currencyFormat'
import styles from './styles.module.scss'

const OfferAllocations: FC<any> = ({ data, claims }) => {
  const getTotalClaimsAmt = () => {
    return claims.reduce((acc: any, cur: any) => acc + +cur.amount, 0)
  }

  const calculateAmt = useCallback(
    (type: string, claimsArr: any = claims) => {
      return claimsArr.reduce(
        (acc: any, cur: any) =>
          acc +
          cur.allocations.reduce(
            (acc: any, cur: any) => acc + +cur.amount[type],
            0
          ),
        0
      )
    },
    [claims]
  )

  const [totalFlat, setTotalFlat] = useState(calculateAmt('flat'))
  const [totalAdvanced, setTotalAdvanced] = useState(calculateAmt('advanced'))
  const [totalOther, setTotalOther] = useState(calculateAmt('other'))
  const [generalTotal, setGeneralTotal] = useState(
    totalFlat + totalAdvanced + totalOther
  )

  const { register, getValues, watch } = useFormContext()

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name?.includes('amount')) {
        if (name?.includes('flat')) {
          setTotalFlat(calculateAmt('flat', value.claims))
        }
        if (name?.includes('advanced')) {
          setTotalAdvanced(calculateAmt('advanced', value.claims))
        }
        if (name?.includes('other')) {
          setTotalOther(calculateAmt('other', value.claims))
        }
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, calculateAmt])

  useEffect(() => {
    setGeneralTotal(totalFlat + totalAdvanced + totalOther)
  }, [totalFlat, totalAdvanced, totalOther])

  return (
    <>
      <div className={styles.body}>
        <div className={styles.grid}>
          <span></span>
          <span>
            <b>Claim #</b>
          </span>
          <span>
            <b>Name</b>
          </span>
          <span>
            <b>Offer Date</b>
          </span>
          <span>
            <b>Claim Amt</b>
          </span>
          <span>
            <b>Flat Amt</b>
          </span>
          <span>
            <b>Advance Amt</b>
          </span>
          <span>
            <b>Other Amt</b>
          </span>
          <span>
            <b>Total Offer</b>
          </span>
          <span>
            <b>Notes</b>
          </span>
        </div>
        {claims?.map((claim: any, index: number) => {
          return (
            <div key={index}>
              <div className={classNames(styles.grid, styles.offer)}>
                <span>
                  <small>{claim.auditType.name}</small>
                </span>
                <span>{claim.number}</span>
                <span>{claim.name}</span>
                <span>{data.offerDate}</span>
                <span>{claim.amount}</span>
              </div>

              {claim?.allocations?.map((allocation: any, i: any) => {
                return (
                  <div key={i} className={styles.grid}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span>{allocation.label.abbreviation}</span>
                    <span>{allocation.amount.claimAllocation}</span>
                    <span>
                      {' '}
                      <Input
                        {...register(
                          `claims[${index}].allocations[${i}].amount.flat`
                        )}
                        type="number"
                        step="any"
                        disabled={allocation.amount.flat.length === 0}
                      />
                    </span>
                    <span>
                      {' '}
                      <Input
                        {...register(
                          `claims[${index}].allocations[${i}].amount.advanced`
                        )}
                        type="number"
                        step="any"
                      />
                    </span>
                    <span>
                      <Input
                        {...register(
                          `claims[${index}].allocations[${i}].amount.other`
                        )}
                        type="number"
                        step="any"
                      />
                    </span>
                    <span>
                      {currencyFormat(
                        +getValues(
                          `claims[${index}].allocations[${i}].amount.flat`
                        ) +
                          +getValues(
                            `claims[${index}].allocations[${i}].amount.advanced`
                          ) +
                          +getValues(
                            `claims[${index}].allocations[${i}].amount.other`
                          )
                      )}
                    </span>
                    <span>
                      <Input
                        {...register(
                          `claims[${index}].allocations[${i}].notes`
                        )}
                      />
                    </span>
                  </div>
                )
              })}
            </div>
          )
        })}
        {claims?.length && (
          <div>
            <div className={styles.grid}>
              <span></span>
              <span></span>
              <span></span>
              <span>
                {' '}
                <b>Total</b>
              </span>
              <span>{currencyFormat(getTotalClaimsAmt())}</span>
              <span>{currencyFormat(totalFlat)}</span>
              <span>{currencyFormat(totalAdvanced)}</span>
              <span>{currencyFormat(totalOther)}</span>
              <span>{currencyFormat(generalTotal)}</span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default OfferAllocations

import React, { FC } from 'react'

import MyDashboardCards from 'features/MyDashboardCards'
import CenteredLogo from 'components/CenteredLogo'

const MyDashboardPage: FC = () => {
  return (
    <>
      <CenteredLogo />
      <br />
      <br />
      <MyDashboardCards />
    </>
  )
}

export default MyDashboardPage

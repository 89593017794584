import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, ColumnsType, Icon } from '@wmgtech/legato'

import convertDateToString from 'utils/convertDateToString'
import currencyFormat from 'utils/currencyFormat'
import notify from 'utils/notify'
import { Notification } from 'constants/typeConstants'
import {
  AuditDocument,
  useAuditClaimsTableQuery,
  useAuditOffersQuery,
  useChangeOfferStatusMutation,
  useCreateAuditOfferMutation,
  useDeleteAuditOfferMutation,
} from 'generated/graphql'
import OfferModal from './OfferModal'
import SubmissionModal from 'components/SubmissionModal'
import Table from 'components/Table'
import OfferStatusForm from './OfferStatusForm'
import StatusChangeModal from './StatusChangeModal'
import styles from './styles.module.scss'

const Offers: FC<any> = ({ auditData }) => {
  const mounted = useRef(false)
  const history = useHistory()

  interface IAllocation {
    amount: {
      flat: string
      advanced: string
      other: string
      claimAllocation: string
    }
  }
  interface ITableRow {
    id: string
    offerDate: string
    status: {
      id: string
      name: string
    }
    allocations: IAllocation[]
  }

  interface ICreateOfferInput {
    offerDate: string
    notes: string
  }

  interface IChangeAuditOfferStatusInput {
    id: string
    statusId: string
  }

  const tableColumns: ColumnsType<any>[] = [
    {
      title: 'Offer Date',
      dataIndex: 'offerDate',
      key: 'offerDate',
    },
    {
      title: 'Flat Amt',
      dataIndex: 'flat',
      key: 'flat',
      render: (value: string, row: ITableRow) =>
        currencyFormat(calculateAmt(row, 'flat')),
    },
    {
      title: 'Advance Amt',
      dataIndex: 'advanced',
      key: 'advanced',
      render: (value: string, row: ITableRow) =>
        currencyFormat(calculateAmt(row, 'advanced')),
    },
    {
      title: 'Other Amt',
      dataIndex: 'other',
      key: 'other',
      render: (value: string, row: ITableRow) =>
        currencyFormat(calculateAmt(row, 'other')),
    },
    {
      title: 'Total Offer',
      dataIndex: 'total',
      key: 'total',
      render: (value: string, row: ITableRow) =>
        currencyFormat(calculateTotalAmt(row)),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (value: string, row: ITableRow) => {
        return (
          <OfferStatusForm
            onSubmit={toggleStatusModal}
            defaultValues={{ statusId: row.status.id, id: row.id }}
          />
        )
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (value: string, row: ITableRow) => {
        return (
          <div>
            <Link to={`/audits/${auditData.id}/offers/${row.id}`}>
              <Icon name="edit" prefix="fas" />
            </Link>
            <span className={styles.separator}>|</span>
            <Button
              onClick={() => {
                setAuditOfferId(row.id)
                toggleSubmissionModal()
              }}
              containerStyle={'link'}
              colorType={'danger'}
              size="sm"
              className={styles.button}
            >
              <Icon name="trash" prefix="fas" />
            </Button>
          </div>
        )
      },
    },
  ]

  const { data: claimsData } = useAuditClaimsTableQuery({
    variables: { auditId: auditData.id },
  })

  const claims = claimsData?.auditClaimsTable.items.length

  const calculateAmt = (row: ITableRow, type: string) => {
    return row.allocations.reduce(
      (acc: number, cur: any) => acc + +cur.amount[type],
      0
    )
  }

  const calculateTotalAmt = (row: ITableRow) => {
    return (
      calculateAmt(row, 'flat') +
      calculateAmt(row, 'advanced') +
      calculateAmt(row, 'other')
    )
  }

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  })

  const { data: offersData, refetch } = useAuditOffersQuery({
    variables: { auditId: auditData.id },
  })

  const [showModal, setShowModal] = useState(false)
  const [showStatusModal, setShowStatusModal] = useState(false)
  const [showSubmissionModal, setShowSubmissionModal] = useState(false)
  const [auditOfferId, setAuditOfferId] = useState('')
  const [statusPayload, setStatusPayload] =
    useState<IChangeAuditOfferStatusInput>()
  const [createOffer] = useCreateAuditOfferMutation()
  const [removeAuditOffer] = useDeleteAuditOfferMutation()
  const [changeStatus] = useChangeOfferStatusMutation()

  const toggleModal = useCallback(() => {
    setShowModal((show) => !show)
  }, [])

  const toggleSubmissionModal = useCallback(() => {
    setShowSubmissionModal((show) => !show)
  }, [])

  const toggleStatusModal = useCallback(
    (data?) => {
      if (!showStatusModal) {
        setStatusPayload(data)
      }
      setShowStatusModal((show) => !show)
    },
    [showStatusModal]
  )

  const createOfferFunc = (data: ICreateOfferInput) => {
    const input = {
      auditId: auditData.id,
      offerDate: convertDateToString(data.offerDate),
      notes: data.notes,
    }

    createOffer({
      variables: { input },
      refetchQueries: [
        {
          query: AuditDocument,
          variables: { input: auditData.id },
        },
      ],
      onCompleted(response) {
        refetch()
        mounted.current && toggleModal()
        history.push(
          `/audits/${auditData.id}/offers/${response.createAuditOffer.id}`
        )
        notify(Notification.SUCCESS, 'Your offer was created!')
      },
      onError() {
        notify(Notification.ERROR, 'There is some error while creating offer')
      },
    })
  }

  const removeOffer = () => {
    removeAuditOffer({
      variables: { input: { id: auditOfferId } },
      onCompleted() {
        refetch()
        mounted.current && toggleSubmissionModal()
        notify(Notification.SUCCESS, 'Your offer was deleted!')
      },
      onError() {
        notify(Notification.ERROR, 'There is some error while deleting offer')
      },
    })
  }

  const changeOfferStatus = () => {
    changeStatus({
      variables: { input: statusPayload! },
      onCompleted() {
        refetch()
        mounted.current && toggleStatusModal()
        notify(Notification.SUCCESS, 'Status was changed!')
      },
      onError() {
        notify(Notification.ERROR, 'There is some error while changing status')
      },
    })
  }

  return (
    <div className={styles.container}>
      <StatusChangeModal
        show={showStatusModal}
        onClose={toggleStatusModal}
        onSubmit={changeOfferStatus}
        statusPayload={statusPayload}
      />
      <OfferModal
        onSubmit={createOfferFunc}
        onClose={toggleModal}
        show={showModal}
      />
      <SubmissionModal
        name="offer"
        show={showSubmissionModal}
        onClose={toggleSubmissionModal}
        onSubmit={removeOffer}
      ></SubmissionModal>
      <header className={styles.header}>
        <div>
          <h4>All Offers</h4>
          <p>
            Below are all the current label offers you have added to this audit
          </p>
        </div>
        <div className={styles['button-wrapper']}>
          {!claims && (
            <Button
              disabled
              secondaryIcon="exclamation"
              label="Add Claims to Audit"
            />
          )}
          {!!claims && (
            <Button
              onClick={toggleModal}
              secondaryIcon="plus"
              label="Add Offer"
              disabled={!claimsData}
            />
          )}
        </div>
      </header>
      <Table columns={tableColumns} data={offersData?.auditOffers ?? []} />
    </div>
  )
}

export default Offers

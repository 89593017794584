import { FC } from 'react'
import { Link, useParams } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { Icon, Input, InputGroup } from '@wmgtech/legato'
import classNames from 'classnames'

import {
  useAuditStatusesListQuery,
  useArtistsListQuery,
  AuditStatusesListQuery,
  ArtistsListQuery,
  useAuditorsListQuery,
  AuditorsListQuery,
  useAuditSubcategoriesListQuery,
  AuditSubcategoriesListQuery,
  MutationCreateAuditArgs,
  MutationUpdateAuditArgs,
} from 'generated/graphql'
import FormFooter from 'components/Form/FormFooter'
import FormSelect from 'components/Form/FormSelect'
import NestedSelectWithTag from 'components/Form/NestedSelectWithTag'
import FormDatepicker from 'components/Form/FormDatepicker'
import FormInputValidated from 'components/Form/FormInputValidated'
import useData from 'hooks/useData'
import getAuditSubcategories from 'utils/getAuditSubcategories'
import convertToList from 'utils/convertToList'
import styles from './styles.module.scss'

interface IAuditForm {
  defaultValues: any
  action: string
  onSubmit: (data: MutationCreateAuditArgs | MutationUpdateAuditArgs) => void
}

const AuditForm: FC<IAuditForm> = ({ action, defaultValues, onSubmit }) => {
  const { id } = useParams<{ id?: string }>()

  const getAuditStatusesListQuery = useAuditStatusesListQuery({
    onCompleted(response) {
      if (!id) {
        const status = response?.auditStatuses![0]!.id || '1'
        methods.reset({ statusId: status })
      }
    },
  })
  const AuditStatusesData = useData<AuditStatusesListQuery>({
    loading: getAuditStatusesListQuery.loading,
    previousData: getAuditStatusesListQuery.previousData,
    data: getAuditStatusesListQuery.data,
  })
  const AuditStatusesList = convertToList(AuditStatusesData?.auditStatuses)

  const getAuditSubcategoriesListQuery = useAuditSubcategoriesListQuery()
  const AuditSubcategoriesData = useData<AuditSubcategoriesListQuery>({
    loading: getAuditSubcategoriesListQuery.loading,
    previousData: getAuditSubcategoriesListQuery.previousData,
    data: getAuditSubcategoriesListQuery.data,
  })

  const auditSubcategories = getAuditSubcategories(
    AuditSubcategoriesData?.auditCategories
  )

  const getAuditorsListQuery = useAuditorsListQuery()
  const AuditorsData = useData<AuditorsListQuery>({
    loading: getAuditorsListQuery.loading,
    previousData: getAuditorsListQuery.previousData,
    data: getAuditorsListQuery.data,
  })
  const AuditorsList = convertToList(AuditorsData?.auditors?.items)

  const getArtistsListQuery = useArtistsListQuery()
  const ArtistsData = useData<ArtistsListQuery>({
    loading: getArtistsListQuery.loading,
    previousData: getArtistsListQuery.previousData,
    data: getArtistsListQuery.data,
  })
  const ArtistsList = convertToList(ArtistsData?.artists?.items)

  const preventLeadingSpace = (event: any) => {
    if (!event.target.value && event.code === 'Space') {
      event.preventDefault()
    }
  }

  const methods = useForm({
    defaultValues: defaultValues,
  })

  const { register } = methods

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
        <header className={styles.header}>
          <Icon name="info-circle" className="fa-2x"></Icon>
          <h4>General Info</h4>
        </header>
        <div
          className={classNames(
            styles['form-group'],
            styles['form-group-three']
          )}
        >
          <FormInputValidated
            defaultValue={defaultValues?.reportName}
            name="reportName"
            label="Reporting Name"
            placeholder="This is the name used for reports"
            message="Report name can't be blank"
            rules={{ required: true }}
          ></FormInputValidated>
          <FormInputValidated
            defaultValue={defaultValues?.name}
            name="name"
            label="Audit Name (unique name)"
            placeholder="Name must be unique"
            message="Audit name can't be blank"
            rules={{ required: true }}
          ></FormInputValidated>
          <InputGroup className={styles['input-group']} label="Audit Status">
            <FormSelect
              name="statusId"
              list={AuditStatusesList || []}
            ></FormSelect>
          </InputGroup>
        </div>
        <div
          className={classNames(styles['form-group'], styles['form-group-one'])}
        >
          <InputGroup className={styles['input-group']} label="Sharepoint URL">
            <Input
              {...register('sharepointUrl')}
              placeholder="Paste the full URL of the audit sharepoint location"
              onKeyDown={preventLeadingSpace}
            />
          </InputGroup>
        </div>
        <div
          className={classNames(
            styles['form-group'],
            styles['form-group-four']
          )}
        >
          <InputGroup className={styles['input-group']} label="Parent ID">
            <FormSelect
              value={defaultValues?.parentId}
              name="parentId"
              list={[]}
              placeholder="Select a parent audit"
              isFilterable
            />
          </InputGroup>
          <InputGroup
            className={styles['input-group']}
            label="External Auditor"
          >
            <FormSelect
              value={defaultValues?.auditorId}
              name="auditorId"
              list={AuditorsList}
              placeholder="Select an auditor"
              isFilterable
            />
          </InputGroup>
          {!id && (
            <NestedSelectWithTag
              isClearable
              selectLabel="Audit Subcategory"
              name="subcategoryIds"
              list={auditSubcategories}
              placeholder="Select one or more types"
              validation={{
                required: true,
                message: "Subcategory array can't be blank",
              }}
            />
          )}
          {id && (
            <div className={styles.centered}>
              {defaultValues.subcategoryIds
                .map((t: { name: string; id: string }) => {
                  return t.name
                })
                .join(', ')}
            </div>
          )}
          <InputGroup className={styles['input-group']} label="Related Artist">
            <FormSelect
              name="artistId"
              list={ArtistsList}
              placeholder="Select an artist"
              isFilterable
            />
          </InputGroup>
        </div>
        <div className="dashes"></div>
        <header className={styles.header}>
          <Icon name="server" className="fa-2x"></Icon>
          <h4>System Identifiers</h4>
        </header>

        <div
          className={classNames(
            styles['form-group'],
            styles['form-group-four']
          )}
        >
          <InputGroup className={styles['input-group']} label="Artist Number">
            <Input
              {...register('sapArtistNumber')}
              placeholder="SAP Artist Num"
              onKeyDown={preventLeadingSpace}
            />
          </InputGroup>
          <InputGroup className={styles['input-group']} label="CRS Number ">
            <Input
              {...register('crsNumber')}
              placeholder="CRS Num"
              onKeyDown={preventLeadingSpace}
            />
          </InputGroup>
          <InputGroup className={styles['input-group']} label="WBS Number">
            <Input
              {...register('wbsProjectNumber')}
              placeholder="WBS/Project Num"
              onKeyDown={preventLeadingSpace}
            />
          </InputGroup>
          <div></div>
        </div>
        <div className="dashes"></div>
        <header className={styles.header}>
          <Icon name="calendar-days" className="fa-2x"></Icon>
          <h4> Important Audit Dates</h4>
        </header>
        <div
          className={classNames(
            styles['form-group'],
            styles['form-group-four']
          )}
        >
          <FormDatepicker
            name="noticeDate"
            label="Notice Date"
            initialDate={defaultValues?.noticeDate || null}
          />
          <FormDatepicker
            name="startDate"
            label="Audit Period Start"
            initialDate={defaultValues?.startDate || null}
            rules={{ required: true }}
            message="Start period can't be blank"
          />
          <FormDatepicker
            name="endDate"
            label="Audit Period End"
            initialDate={defaultValues?.endDate || null}
          />
          <FormDatepicker
            name="suitDate"
            label="Suit Date"
            initialDate={defaultValues?.suitDate || null}
          />
        </div>
        <footer>
          <FormFooter
            cancel={<Link to={'/audits/'}>Cancel</Link>}
            submit={action}
          />
        </footer>
      </form>
    </FormProvider>
  )
}

export default AuditForm

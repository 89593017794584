import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { LabelsListDocument, useCreateLabelMutation } from 'generated/graphql';
import styles from './styles.module.scss';
import notify from 'utils/notify';
import { Notification } from 'constants/typeConstants';
import LabelForm from '../LabelForm';

const CreateLabel: FC = () => {
  const history = useHistory()

  const [createLabel, { loading }] = useCreateLabelMutation()

  const createLabelFunc = (input: any) => {
    createLabel({
      variables: { input },
      refetchQueries:[{
        query: LabelsListDocument
      }]
    }).then(() => history.push('/labels'))
      .catch(() => notify(Notification.ERROR, 'There is some error while creating the label'))
  }

  const defaultValues = {
    name: '',
    abbreviation: '',
    description: ''
  }

  return (
    <div>
      <header className={styles.header}>
        <h2>New Label</h2>
      </header>
      <LabelForm
        action={'create'}
        defaultValues={defaultValues}
        onSubmit={createLabelFunc}
        loading={loading}
      />
    </div>
  )
}

export default CreateLabel

import { Route, Switch, useHistory } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import { GrowlContainer } from '@wmgtech/legato'

import AuthApolloProvider from './apollo'
import theme from './theme'
import './index.scss'
import GlobalStyles from './Global.styles'

import MainLayout from 'components/MainLayout'
import { OKTA_CONFIG } from 'constants/oktaConfig'
import MyDashboardPage from 'pages/MyDashboardPage'
import MyAuditsPage from 'pages/MyAuditsPage'
import ExecDashboardPage from 'pages/ExecDashboardPage'
import AdminControlsPage from 'pages/AdminControlsPage'
import UserProfilePage from 'pages/UserProfilePage'
import AuditPage from 'pages/AuditPage'
import AddNewAuditPage from 'pages/AddNewAuditPage'
import HomePage from 'pages/HomePage'
import NotFoundPage from 'pages/NotFoundPage'
import EditAuditPage from 'pages/EditAuditPage'
import UpdateAuditLabelPage from 'pages/UpdateAuditLabelPage'
import AddReserveCalculationPage from 'pages/AddReserveCalculationPage'
import UpdateReserveCalculationPage from 'pages/UpdateReserveCalculationPage'
import LabelsPage from 'pages/LabelsPage'
import LabelRatesPage from 'pages/LabelRatesPage'
import ArtistsPage from 'pages/ArtistsPage'
import AuditorsPage from 'pages/AuditorsPage'
import EditArtistPage from 'pages/EditArtistPage'
import CreateArtistPage from 'pages/CreateArtistPage'
import ReservesHistoryPage from 'pages/ReservesHistoryPage'
import CreateLabelPage from 'pages/CreateLabelPage'
import EditLabelPage from 'pages/EditLabelPage'
import EditAuditorPage from 'pages/EditAuditorPage'
import CreateAuditorPage from 'pages/CreateAuditorPage'
import RatesPage from 'pages/RatesPage'
import ActivityTypesPage from 'pages/ActivityTypesPage'
import EditActivityTypePage from 'pages/EditActivityTypePage'
import CreateActivityTypePage from 'pages/CreateActivityTypePage'
import AuditActivitiesHistoryPage from 'pages/AuditActivitiesHistoryPage'
import ActivityCommentsPage from 'pages/ActivityCommentsPage'
import RevisionsPage from 'pages/RevisionsPage'
import OfferPage from 'pages/OfferPage'

const oktaAuth: OktaAuth = new OktaAuth(OKTA_CONFIG)

const App = () => {
  const history = useHistory()
  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string
  ): Promise<void> => {
    history.replace(toRelativeUrl(originalUri, window.location.origin))
  }

  return (
    <EmotionThemeProvider theme={theme}>
      <GlobalStyles />
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <AuthApolloProvider>
          <GrowlContainer
            position="bottom-left"
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
          />
          <QueryParamProvider ReactRouterRoute={Route}>
            <MainLayout>
              <Switch>
                <SecureRoute exact path="/" component={HomePage} />
                <SecureRoute
                  exact
                  path="/home/dashboard"
                  component={MyDashboardPage}
                />
                <SecureRoute exact path="/audits" component={MyAuditsPage} />
                <SecureRoute
                  exact
                  path="/home/admin_controls"
                  component={AdminControlsPage}
                />
                <SecureRoute exact path="/labels" component={LabelsPage} />
                <SecureRoute
                  exact
                  path="/labels/:id/edit"
                  component={EditLabelPage}
                />
                <SecureRoute
                  exact
                  path="/labels/new"
                  component={CreateLabelPage}
                />
                <SecureRoute
                  exact
                  path="/labels/:id/rates"
                  component={LabelRatesPage}
                />
                <SecureRoute exact path="/auditors" component={AuditorsPage} />
                <SecureRoute
                  exact
                  path="/auditors/:id/edit"
                  component={EditAuditorPage}
                />
                <SecureRoute
                  exact
                  path="/auditors/new"
                  component={CreateAuditorPage}
                />
                <SecureRoute
                  exact
                  path="/activity-types"
                  component={ActivityTypesPage}
                />
                <SecureRoute
                  exact
                  path="/activity-types/:id/edit"
                  component={EditActivityTypePage}
                />
                <SecureRoute
                  exact
                  path="/activity-types/new"
                  component={CreateActivityTypePage}
                />
                <SecureRoute exact path="/artists" component={ArtistsPage} />
                <SecureRoute
                  exact
                  path="/artists/:id/edit"
                  component={EditArtistPage}
                />
                <SecureRoute
                  exact
                  path="/artists/new"
                  component={CreateArtistPage}
                />
                <SecureRoute exact path="/rates" component={RatesPage} />
                <SecureRoute
                  exact
                  path="/super_dashboard"
                  component={ExecDashboardPage}
                />
                <SecureRoute
                  exact
                  path="/users/edit"
                  component={UserProfilePage}
                />
                <SecureRoute
                  exact
                  path="/audits/new"
                  component={AddNewAuditPage}
                />
                <SecureRoute exact path="/audits/:id" component={AuditPage} />
                <SecureRoute
                  exact
                  path="/audits/:id/edit"
                  component={EditAuditPage}
                />
                <SecureRoute
                  exact
                  path="/audits/:auditId/labels/:labelId/edit"
                  component={UpdateAuditLabelPage}
                />
                <SecureRoute
                  exact
                  path="/audits/:auditId/types/:auditTypeId/labels/:labelId/ramounts/new"
                  component={AddReserveCalculationPage}
                />
                <SecureRoute
                  exact
                  path="/audits/:auditId/types/:auditTypeId/labels/:labelId/ramounts/:ramountId/edit"
                  component={UpdateReserveCalculationPage}
                />
                <SecureRoute
                  exact
                  path="/audits/:auditId/types/:auditTypeId/labels/:labelId/ramounts"
                  component={ReservesHistoryPage}
                />
                <SecureRoute
                  exact
                  path="/audits/:auditId/revisions"
                  component={RevisionsPage}
                />
                <SecureRoute
                  exact
                  path="/audits/:auditId/offers/:offerId"
                  component={OfferPage}
                />
                <SecureRoute
                  exact
                  path="/audits/:auditId/activities"
                  component={AuditActivitiesHistoryPage}
                />
                <SecureRoute
                  exact
                  path="/audits/:auditId/activities/:auditActivityId/activity_comments"
                  component={ActivityCommentsPage}
                />
                <Route
                  path={process.env.REACT_APP_OKTA_AUTH_CALLBACK}
                  component={LoginCallback}
                />
                <Route component={NotFoundPage} />
              </Switch>
            </MainLayout>
          </QueryParamProvider>
        </AuthApolloProvider>
      </Security>
    </EmotionThemeProvider>
  )
}

export default App

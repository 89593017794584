import { FC } from 'react'
import { Link } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { Input } from '@wmgtech/legato'
import { MutationCreateAuditArgs, MutationUpdateAuditArgs, } from 'generated/graphql'
import FormFooter from 'components/Form/FormFooter'
import FormInputValidated from 'components/Form/FormInputValidated'

interface ILabelForm {
  defaultValues: any
  action: string
  onSubmit: (data: MutationCreateAuditArgs | MutationUpdateAuditArgs) => void
  loading: boolean
}

const LabelForm: FC<ILabelForm> = ({ action, defaultValues, onSubmit, loading }) => {
  const methods = useForm({
    defaultValues: defaultValues,
  })

  const { register } = methods

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
        <div className='form-group form-group-three'>
          <FormInputValidated
            defaultValue={defaultValues?.name}
            name="name"
            label="Label Name"
            placeholder="Name must be unique"
            message="Label Name can't be blank"
            rules={{ required: true }}
          ></FormInputValidated>
          <FormInputValidated
            defaultValue={defaultValues?.abbreviation}
            name="abbreviation"
            label="Label Abbreviation (unique abbreviation)"
            placeholder="Abbreviation must be unique"
            message="Label Abbreviation can't be blank"
            rules={{ required: true }}
          ></FormInputValidated>
        </div>
        <div className='form-group form-group-three'>
          <Input
            {...register('description')}
            defaultValue={defaultValues?.description}
            name="description"
            placeholder="Label Description"
          />
        </div>
        <footer>
          <FormFooter
            cancel={<Link to={'/labels'}>Cancel</Link>}
            submit={action}
            loading={loading}
          />
        </footer>
      </form>
    </FormProvider>
  )
}

export default LabelForm

import React, { FC } from 'react'

import logo from 'assets/images/Audit_Logo.png'
import LoadingButton from 'components/LoadingButton'
import styles from './styles.module.scss'

type DashboardHeaderProps = {
  labels: { id: string; name: string }[]
  activeLabels: (string | null)[]
}

const ExecDashboardHeader: FC<DashboardHeaderProps> = ({
  labels,
  activeLabels,
}) => {
  const handleDownloadStatusReport = () => {
    // TODO: implement download status report
  }

  const handleDownloadConsolidatedBalances = () => {
    // TODO: implement download consolidated balances
  }

  const getActiveLabels = () => {
    const activeLabelsNames = []
    for (let label of labels) {
      if (activeLabels.includes(label.id)) activeLabelsNames.push(label.name)
    }
    return activeLabelsNames.length
      ? activeLabelsNames.slice(0, activeLabelsNames.length - 1).join(', ') +
          (activeLabelsNames.length > 1 ? ' and ' : '') +
          activeLabelsNames.slice(-1)
      : ' all label'
  }

  return (
    <div className={styles.container}>
      <div>
        <img width="200px" src={logo} alt="Audit TraX" />
      </div>
      <div>
        <h1 className={styles.title}>Welcome to the Executive Dashboard!</h1>
        <p>
          <i>You are currently viewing {getActiveLabels()} data.</i>
        </p>
      </div>
      <div>
        <strong>Available Reports</strong>
        <div>
          <LoadingButton
            className={styles.button}
            onClick={handleDownloadStatusReport}
            prefix="fas"
            containerStyle={'outline'}
            colorType={'success'}
            icon="download"
          >
            Status Report
          </LoadingButton>
          <LoadingButton
            className={styles.button}
            onClick={handleDownloadConsolidatedBalances}
            prefix="fas"
            containerStyle={'outline'}
            colorType={'success'}
            icon="download"
          >
            Consolidated Balances
          </LoadingButton>
        </div>
      </div>
    </div>
  )
}

export default ExecDashboardHeader

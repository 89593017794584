import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Icon, Input, Table } from '@wmgtech/legato'
import {
  Label,
  LabelsListDocument,
  LabelsListQuery,
  useDeleteLabelMutation,
  useLabelsListQuery
} from '../../generated/graphql'
import useData from '../../hooks/useData'
import { ColumnsType } from '@wmgtech/legato/build/components/Table/types'
import { Link, useHistory } from 'react-router-dom'
import styles from './styles.module.scss'
import SubmissionModal from '../../components/SubmissionModal/SubmissionModal'
import notify from '../../utils/notify'
import { Notification } from '../../constants/typeConstants'
import Breadcrumbs from '../../components/Breadcrumbs'
import { IBreadcrumbs } from '../../interfaces/BreadCrumbsInterface'
import LoadingButton from '../../components/LoadingButton'
import { debounce } from 'lodash';

interface ITableItem {
  id: string;
  name: string;
  abbreviation: string;
  activeAudits: number;
  ratesLink: string;
}

const Labels: FC = () => {
  const history = useHistory()
  const [labelId, setLabelId] = useState('')
  const [showSubmissionModal, setShowSubmissionModal] = useState(false)
  const [deleteLabel] = useDeleteLabelMutation()
  const { loading, previousData, data } = useLabelsListQuery()
  const labelListData = useData<LabelsListQuery>({ loading, previousData, data })
  const mounted = useRef(false)

  const toggleSubmissionModal = useCallback(() => {
    setShowSubmissionModal((show) => !show)
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  })

  const [search, setSearch] = useState('')
  const labels: Label[] = (labelListData?.labels?.items as Label[])
  const labelsFiltered = useMemo<Label[]>(
    (): Label[] => (labels || []).filter(label => label?.name?.toLowerCase().includes(search.toLowerCase())),
    [search, labels]
  )

  const removeAuditLabel = () => {
    deleteLabel({
      variables: { id: labelId },
      update(cache: any, MutationResult: any) {
        const newLabels = labels?.filter((label) => label?.id !== MutationResult.data?.deleteLabel.id)
        cache.writeQuery({
          query: LabelsListDocument,
          data: {
            labels: {
              total: newLabels?.length,
              items: newLabels,
            }
          },
        })
      }
    }).then(() => {
      mounted.current && toggleSubmissionModal()
      notify(Notification.SUCCESS, 'You have deleted a label')
    }).catch(() => {
      notify(
        Notification.ERROR,
        'There is some error while deleting audit label'
      )
    })
  }

  const handleAddNewLabel = () => {
    history.push('/labels/new')
  }

  const handleSearchLabel = debounce((event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value.trim())
  }, 1000)

  const tableData: ITableItem[] = labelsFiltered?.map((label: any) => ({
    id: label?.id || '',
    name: label?.name || '',
    abbreviation: label?.abbreviation || '',
    activeAudits: label?.auditsCount || 0,
    ratesLink: 'link here',
    key: label?.id || ''
  })) || []

  const breadcrumbs: IBreadcrumbs[] = [
    { name: 'Home', to: '/' },
    { name: 'Admin Controls', to: '/home/admin_controls' },
    { name: 'Labels' }
  ]

  const tableColumns: ColumnsType<any>[] = [
    {
      dataIndex: 'name',
      key: 'name',
      sortable: false,
      title: 'Label Name',
      width: 250
    },
    {
      dataIndex: 'abbreviation',
      key: 'abbreviation',
      sortable: false,
      title: 'Label Abbrev'
    },
    {
      dataIndex: 'activeAudits',
      key: 'activeAudits',
      sortable: false,
      title: 'Active Audits'
    },
    {
      dataIndex: 'ratesLink',
      key: 'ratesLink',
      sortable: false,
      title: 'Current Default Rate',
      render: (value: string, row: ITableItem) => {
        return (
          <div>
            <Link to={`/labels/${row.id}/rates`}>Rate sheets</Link>
          </div>
        )
      }
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (value: string, row: ITableItem) => {
        return (
          <div>
            <Link to={`/labels/${row.id}/edit`}>
              <Icon name="edit" prefix="fas" />
            </Link>
            <span className={styles.separator}>|</span>
            <Button
              onClick={() => {
                setLabelId(row.id)
                toggleSubmissionModal()
              }}
              containerStyle={'link'}
              colorType={'danger'}
              size="sm"
              className={styles.button}
            >
              <Icon name="trash" prefix="fas" />
            </Button>
          </div>
        )
      }
    }
  ]

  return (
    <div>
      <header className={styles.header}>
        <h2>Labels</h2>
        <Breadcrumbs links={breadcrumbs} />
        <div className={styles.item}>
          <LoadingButton onClick={handleAddNewLabel} icon="plus">
            Add New Label
          </LoadingButton>
        </div>
      </header>
      <SubmissionModal
        name="label"
        show={showSubmissionModal}
        onClose={toggleSubmissionModal}
        onSubmit={removeAuditLabel}
      />
      {
        loading
          ? <p>loading...</p>
          : <>
            <div className='form-group form-group-one'>
              <Input
                placeholder="Search Label"
                onChange={handleSearchLabel}
              />
            </div>
            <Table
            condensed={true}
            pagination={{
              position: ['bottomCenter']
            }}
            columns={tableColumns}
            data={tableData}
            striped="none"
          />
        </>
      }
    </div>
  )
}

export default Labels

import { FC } from 'react'
import { Modal } from '@wmgtech/legato'
import { useParams } from 'react-router-dom'

import ClaimForm from '../ClaimForm'
import { useAuditQuery } from 'generated/graphql'
import styles from './styles.module.scss'

interface IProps {
  show: boolean
  onClose: () => void
  onSubmit: (data: any) => void
  claims: any[]
  claim: any
}

const EditClaimModal: FC<IProps> = ({ show, onClose, onSubmit, claim }) => {
  const { auditId } = useParams<{ auditId: string }>()
  const { data: auditData } = useAuditQuery({
    variables: { input: auditId! },
    fetchPolicy: 'no-cache',
  })

  const defaultAudit =
    auditData?.audit?.types?.length === 1
      ? auditData?.audit?.types[0]?.id
      : claim?.auditType.id

  const findValuesForSelect = (obj: any) => {
    if (obj) return obj?.key ? obj : { title: obj.name, key: obj.id }
    return ''
  }

  const defaultValues = {
    auditTypeId: defaultAudit,
    number: claim?.number,
    name: claim?.name,
    amount: claim?.amount,
    type: findValuesForSelect(claim?.type),
    subcategory: findValuesForSelect(claim?.subcategory),
    action: findValuesForSelect(claim?.action),
  }

  return (
    <Modal
      className={styles.modal}
      isOpen={show}
      title={
        <div className={styles['modal-title']}>
          <h2>Edit Revision Claim</h2>
          <div className="divider"></div>
          <p>
            Enter the TOTAL Claim - you will have the ability to allocate across
            the labels in the next screen.
          </p>
        </div>
      }
      onClose={onClose}
      shouldCloseOnOverlayClick
    >
      <ClaimForm
        onClose={onClose}
        onSubmit={onSubmit}
        auditData={auditData}
        defaultValues={defaultValues}
      />
    </Modal>
  )
}

export default EditClaimModal

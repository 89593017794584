import { FC } from 'react'
import { Icon, Modal } from '@wmgtech/legato'

import styles from './styles.module.scss'
import AuditActivityForm from '../AuditActivityForm/AuditActivityForm'

interface IAddActivityProps {
  show: boolean
  auditId: string | undefined | null
  onClose: () => void
  onSubmit: (data: any) => void
}

const AddActivityModal: FC<IAddActivityProps> = ({
  show,
  auditId,
  onClose,
  onSubmit,
}) => {
  return (
    <Modal
      className={styles.modal}
      isOpen={show}
      title={
        <div className={styles['modal-title']}>
          <Icon className={styles['modal-title-icon']} name="calendar-alt" />
          <h2>Add a New Activity</h2>
          <p>
            This is where you will add a new activity - either an ACTUAL date or
            a PROJECTED date
            <br />
            (actual dates should not be in the future).
          </p>
        </div>
      }
      onClose={onClose}
      shouldCloseOnOverlayClick
    >
      <AuditActivityForm
        onClose={onClose}
        onSubmit={onSubmit}
        auditId={auditId}
      ></AuditActivityForm>
    </Modal>
  )
}

export default AddActivityModal

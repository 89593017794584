import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Icon, Input, Table } from '@wmgtech/legato'
import useData from '../../hooks/useData'
import { ColumnsType } from '@wmgtech/legato/build/components/Table/types'
import { Link, useHistory } from 'react-router-dom'
import styles from './styles.module.scss'
import SubmissionModal from '../../components/SubmissionModal/SubmissionModal'
import notify from '../../utils/notify'
import { Notification } from '../../constants/typeConstants'
import Breadcrumbs from '../../components/Breadcrumbs'
import { IBreadcrumbs } from '../../interfaces/BreadCrumbsInterface'
import {
  Artist,
  ArtistsListDocument,
  ArtistsListQuery,
  useArtistsListQuery,
  useDeleteArtistMutation
} from '../../generated/graphql'
import LoadingButton from '../../components/LoadingButton';
import { debounce } from 'lodash'

interface ITableItem {
  id: string;
  name: string;
  activeAudits: number;
}

const Artists: FC = () => {
  const history = useHistory()
  const [artistId, setArtistId] = useState('')
  const [showSubmissionModal, setShowSubmissionModal] = useState(false)
  const [deleteArtist] = useDeleteArtistMutation()
  const { loading, previousData, data } = useArtistsListQuery()
  const artistListData = useData<ArtistsListQuery>({ loading, previousData, data })
  const mounted = useRef(false)

  const toggleSubmissionModal = useCallback(() => {
    setShowSubmissionModal((show) => !show)
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  })

  const [search, setSearch] = useState('')
  const artists: Artist[] = (artistListData?.artists?.items as Artist[])
  const artistsFiltered = useMemo<Artist[]>(
    (): Artist[] => (artists || []).filter(artist => artist?.name?.toLowerCase().includes(search.toLowerCase())),
    [search, artists]
  )

  const removeAuditArtist = () => {
    deleteArtist({
      variables: { id: artistId },
      update(cache: any, MutationResult: any) {
        const newArtists = artists?.filter((artist: Artist) => artist?.id !== MutationResult.data?.deleteArtist.id)
        cache.writeQuery({
          query: ArtistsListDocument,
          data: {
            artists: {
              total: newArtists?.length,
              items: newArtists
            }
          }
        })
      }
    }).then(() => {
      mounted.current && toggleSubmissionModal()
      notify(Notification.SUCCESS, 'You have deleted an artist')
    }).catch(() => {
      notify(
        Notification.ERROR,
        'There is some error while deleting artist'
      )
    })
  }

  const tableData: ITableItem[] = artistsFiltered?.map((artist: any) => ({
    id: artist?.id || '',
    name: artist?.name || '',
    activeAudits: artist?.auditsCount || 0,
    key: artist?.id || ''
  })) || []

  const breadcrumbs: IBreadcrumbs[] = [
    { name: 'Home', to: '/' },
    { name: 'Admin Controls', to: '/home/admin_controls' },
    { name: 'Artists' }
  ]

  const handleAddNewArtist = () => {
    history.push('/artists/new')
  }

  const handleSearchArtist = debounce((event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value.trim())
  }, 1000)

  const defaultValues = { name: '' }

  const tableColumns: ColumnsType<any>[] = [
    {
      dataIndex: 'name',
      key: 'name',
      sortable: false,
      title: 'Artist Name'
    },
    {
      dataIndex: 'activeAudits',
      key: 'activeAudits',
      sortable: false,
      title: 'Active Audits'
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (value: string, row: ITableItem) => {
        return (
          <div>
            <Link to={`/artists/${row.id}/edit`}>
              <Icon name="edit" prefix="fas" />
            </Link>
            <span className={styles.separator}>|</span>
            <Button
              onClick={() => {
                setArtistId(row.id)
                toggleSubmissionModal()
              }}
              containerStyle={'link'}
              colorType={'danger'}
              size="sm"
              className={styles.button}
            >
              <Icon name="trash" prefix="fas" />
            </Button>
          </div>
        )
      }
    }
  ]

  return (
    <div>
      <header className={styles.header}>
        <h2>Artists</h2>
        <Breadcrumbs links={breadcrumbs} />
        <div className={styles.item}>
          <LoadingButton onClick={handleAddNewArtist} icon="plus">
            Add New Artist
          </LoadingButton>
        </div>
      </header>
      <SubmissionModal
        name="artist"
        show={showSubmissionModal}
        onClose={toggleSubmissionModal}
        onSubmit={removeAuditArtist}
      />

      {loading
        ? <p>loading...</p>
        : <>
          <div className='form-group form-group-one'>
            <Input
              placeholder="Search Artist"
              onChange={handleSearchArtist}
              defaultValue={defaultValues.name}
            />
          </div>
          <Table
            condensed={true}
            pagination={{
              position: ['bottomCenter']
            }}
            columns={tableColumns}
            data={tableData}
            striped="none"
          />
        </>
      }
    </div>
  )
}

export default Artists

import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Icon } from '@wmgtech/legato'
import classNames from 'classnames'

import {
  AuditOverviewQuery,
  AuditQuery,
  useAuditOverviewQuery,
  useAuditQuery,
} from 'generated/graphql'
import useData from 'hooks/useData'
import ActivityRow from 'components/ActivityRow'
import Spinner from 'components/Spinner'
import AuditAnalysisTable from './AuditAnalysisTable'
import currencyFormat from 'utils/currencyFormat'
import styles from './styles.module.scss'

const Overview: FC = () => {
  const { id } = useParams<{ id: string }>()
  const { data, loading, previousData } = useAuditQuery({
    variables: { input: id },
  })

  const auditActivities = useData<AuditQuery>({ loading, previousData, data })

  const activities = [
    ...((auditActivities?.audit?.activities as any[]) || []),
  ].sort((a, b) =>
    (b?.actualDate ?? b?.manuallyProjectedDate).localeCompare(
      a?.actualDate ?? a?.manuallyProjectedDate
    )
  )

  const lastActivity = [...activities]
    .filter((a) => a.actualDate)
    .sort((a, b) => (b?.actualDate).localeCompare(a?.actualDate))[0]

  const {
    data: overview,
    loading: overviewLoading,
    previousData: prevOverview,
  } = useAuditOverviewQuery({
    variables: { id },
    fetchPolicy: 'no-cache',
    onCompleted() {
      setLabels(getLabels())
    },
  })

  const overviewData = useData<AuditOverviewQuery>({
    loading: overviewLoading,
    previousData: prevOverview,
    data: overview,
  })

  const overviewItems = overviewData?.auditOverview?.items

  const getLabels = (name?: string) => {
    if (name) {
      return overviewItems?.find((item) => item?.name === name)?.auditLabels
    }
    const labels: any = []
    if (overviewItems) {
      for (let i of overviewItems) {
        for (let label of i?.auditLabels!) {
          const index = labels.findIndex((l: any) => l.name === label?.name)
          if (index === -1) {
            labels.push(label)
          } else {
            const claims = {
              amount:
                Number(label?.claims?.amount) +
                Number(labels[index].claims.amount),
            }

            const reserved = {
              amount:
                Number(label?.reserved?.amount) +
                Number(labels[index].reserved.amount),
            }

            const offer = {
              amount: {
                flat:
                  Number(label?.offer?.amount?.flat) +
                  Number(labels[index].offer?.amount?.flat),
                advanced:
                  Number(label?.offer?.amount?.advanced) +
                  Number(labels[index].offer?.amount?.advanced),
                other:
                  Number(label?.offer?.amount?.other) +
                  Number(labels[index].offer?.amount?.other),
              },
              status: labels[index].offer.status,
            }

            const newLabel = {
              ...labels[index],
              claims,
              offer,
              reserved,
            }
            labels[index] = newLabel
          }
        }
      }
    }
    return labels
  }

  const [labelsOverview, setLabels] = useState([])
  const [auditType, setAuditType] = useState()

  const types = overviewItems?.map((item) => {
    return { name: item?.name, id: item?.id }
  })

  const filterByAuditType = (type?: any) => {
    setLabels(getLabels(type ? type.name : null))
    setAuditType(type)
  }

  const netReserve = labelsOverview?.reduce((acc: any, cur: any) => {
    return acc + Number(cur.reserved.amount)
  }, 0)

  return (
    <div className={styles.container}>
      <div className="row">
        <div className={classNames(styles.block, 'col-md-5')}>
          <div className={styles.row}>
            <Icon name="money-bill-1" className="fa-sharp fa-2x"></Icon>
            <h4>Reserve Analysis</h4>
          </div>
          {overviewLoading && <Spinner name="circle-notch" />}
          {overview && (
            <AuditAnalysisTable
              types={types}
              labelsOverview={labelsOverview}
              auditType={auditType}
            />
          )}
          {types && types?.length > 1 && (
            <div className={styles.buttons}>
              {types?.map((type) => (
                <Button
                  key={type?.id}
                  label={type?.name ?? ''}
                  containerStyle={'outline'}
                  colorType={'success'}
                  size="sm"
                  onClick={() => filterByAuditType(type)}
                ></Button>
              ))}
              <Button
                label="Consolidated"
                containerStyle={'outline'}
                colorType={'primary'}
                size="sm"
                onClick={() => filterByAuditType()}
              ></Button>
            </div>
          )}
        </div>
        <div className={classNames([styles['icon-container'], 'col-md-2'])}>
          {(!!netReserve || netReserve === 0) && (
            <Icon
              name={netReserve >= 0 ? 'circle-check' : 'warning'}
              prefix="fas"
              className={classNames([
                netReserve >= 0 ? styles.positive : styles.negative,
                'fa-4x',
              ])}
            />
          )}
          <span>
            <h2>{currencyFormat(netReserve)}</h2>
          </span>
          <span>
            <b>Net Reserve</b>
          </span>
          <span>
            <small>Amount</small>
          </span>
        </div>
        <div className={classNames(styles.block, 'col-md-5')}>
          <div className={styles.row}>
            <Icon name="tasks" className="fa-2x"></Icon>
            <h4>Last 5 Activities</h4>
          </div>
          {activities?.slice(0, 5).map((activity) => (
            <div key={activity?.id}>
              <ActivityRow
                activity={activity}
                last={lastActivity?.id === activity?.id}
              />
            </div>
          ))}
          {loading && <Spinner name="circle-notch" />}
        </div>
      </div>
    </div>
  )
}

export default Overview

import { FC } from 'react'
import { Link } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { MutationCreateAuditArgs, MutationUpdateAuditArgs } from 'generated/graphql'
import FormFooter from 'components/Form/FormFooter'
import FormInputValidated from 'components/Form/FormInputValidated'

interface IArtistForm {
  defaultValues: any
  action: string
  onSubmit: (data: MutationCreateAuditArgs | MutationUpdateAuditArgs) => void
  loading: boolean
}

const ArtistForm: FC<IArtistForm> = ({ action, defaultValues, onSubmit, loading }) => {
  const methods = useForm({
    defaultValues: defaultValues,
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
        <div className='form-group form-group-three'>
          <FormInputValidated
            defaultValue={defaultValues?.name}
            name="name"
            label="Artist Name"
            placeholder="Name must be unique"
            message="Artist Name can't be blank"
            rules={{ required: true }}
          ></FormInputValidated>
        </div>
        <footer>
          <FormFooter
            cancel={<Link to={'/artists'}>Cancel</Link>}
            submit={action}
            loading={loading}
          />
        </footer>
      </form>
    </FormProvider>
  )
}

export default ArtistForm

import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { InputGroup, Textarea } from '@wmgtech/legato'

import LoadingButton from 'components/LoadingButton'
import FormDatepicker from 'components/Form/FormDatepicker'
import styles from './styles.module.scss'

interface IFormProps {
  onClose?: () => void
  onSubmit: (data: any) => void
  revisionId?: string
  defaultValues?: any
}

const RevisionForm: FC<IFormProps> = ({
  onClose,
  onSubmit,
  revisionId,
  defaultValues,
}) => {
  const methods = useForm({ defaultValues: defaultValues })
  const { register } = methods

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
          <div className={styles.body}>
            <input hidden name="id"></input>
            <div className="col-md-6">
              <FormDatepicker
                placeholder="Select a Date"
                name="revisionDate"
                label="Revision Date"
                initialDate={defaultValues?.revisionDate || null}
                rules={{ required: true }}
                message="Revision date can't be blank"
              />
            </div>
            <InputGroup label="Revision Note">
              <Textarea
                {...register('notes')}
                name="notes"
                placeholder="Please leave a note regarding this revision"
              />
            </InputGroup>
          </div>
          <footer className={styles.footer}>
            {onClose && (
              <LoadingButton
                containerStyle={'outline'}
                colorType={'secondary-black'}
                onClick={onClose}
              >
                Cancel
              </LoadingButton>
            )}
            <LoadingButton
              type="submit"
              containerStyle={'outline'}
              colorType={'primary'}
            >
              {revisionId ? 'Update Revision' : 'Create Revision'}
            </LoadingButton>
          </footer>
        </form>
      </FormProvider>
    </>
  )
}

export default RevisionForm

import { FC, useCallback, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useHistory } from 'react-router'
import { Button, Icon } from '@wmgtech/legato'

import Table from 'components/Table'
import currencyFormat from 'utils/currencyFormat'
import LabelSelectionModal from './LabelSelectionModal/LabelSelectionModal'
import styles from './styles.module.scss'
import { getCalculationDetails } from 'utils/reserveCalculations'
import { AuditQuery, useAuditQuery } from 'generated/graphql'
import useData from 'hooks/useData'

const Reserves: FC<any> = ({ auditData }) => {
  const { id } = useParams<{ id?: string }>()
  const columns = [
    {
      title: 'Label',
      dataIndex: 'auditLabel',
      key: 'auditLabel',
      render: (value: string, row: any) => {
        return row.audit.auditLabel.name
      },
    },
    {
      title: 'Type',
      dataIndex: 'auditType',
      key: 'auditType',
      render: (value: string, row: any) => row.audit.auditType.name,
    },
    {
      title: 'Date',
      dataIndex: 'reservedDate',
      key: 'reservedDate',
    },
    {
      title: 'Change Reason',
      dataIndex: 'reason',
      key: 'reason',
      render: (value: string, row: any) => row.reason.name,
    },
    {
      title: 'Earnings',
      dataIndex: 'earnings',
      key: 'earnings',
      render: (value: string, row: any) =>
        row.earnings ? currencyFormat(row.earnings) : '-',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      render: (value: string, row: any) => (row.rate ? row.rate + '%' : '-'),
    },
    {
      title: 'FPP Amt',
      dataIndex: 'fpp',
      key: 'fpp',
      render: (value: string, row: any) =>
        row.fpp ? currencyFormat(row.fpp) : '-',
    },
    {
      title: 'Other Amt',
      dataIndex: 'otherAmount',
      key: 'otherAmount',
      render: (value: string, row: any) =>
        row.otherAmount ? currencyFormat(row.otherAmount) : '-',
    },
    {
      title: 'Add. Amt',
      dataIndex: 'addAmount',
      key: 'addAmount',
      render: (value: string, row: any) =>
        row.additionalAmount ? currencyFormat(row.additionalAmount) : '-',
    },
    {
      title: 'Prev Res',
      dataIndex: 'prevReserve',
      key: 'prevReserve',
      render: (value: string, row: any) =>
        row.previousAmount ? currencyFormat(row.previousAmount) : '-',
    },
    {
      title: 'Reserve Amt',
      dataIndex: 'resAmount',
      key: 'resAmount',
      render: (value: string, row: any) => currencyFormat(row.amount),
    },
    {
      title: 'Historical',
      dataIndex: 'history',
      key: 'history',
      render: (value: string, row: any) => {
        return (
          <Link
            to={`/audits/${auditData.id}/types/${row.audit.auditType.id}/labels/${row.audit.auditLabel.id}/ramounts`}
          >
            View History
          </Link>
        )
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'editable',
      render: (value: string, row: any) => {
        return (
          <Link
            to={`/audits/${row.audit.id}/types/${row.audit.auditType.id}/labels/${row.audit.auditLabel.id}/ramounts/${row.id}/edit`}
          >
            <Icon name="edit" prefix="fas" />
          </Link>
        )
      },
    },
  ]

  const getAuditReserves = useAuditQuery({
    variables: { input: id! },
    fetchPolicy: 'no-cache',
  })

  const auditReserves = useData<AuditQuery>({
    loading: getAuditReserves.loading,
    previousData: getAuditReserves.previousData,
    data: getAuditReserves.data,
  })

  const history = useHistory()
  const labelsCount = auditData.labels.length
  const auditTypesCount = auditData.types.length
  const defaultLabel = labelsCount === 1 ? auditData.labels[0].id : ''
  const defaultAudit = auditTypesCount === 1 ? auditData.types[0].id : ''

  const defaultValues = {
    labelId: defaultLabel,
    auditTypeId: defaultAudit,
  }

  const [showLabelSelectionModal, setShowLabelSelectionModal] = useState(false)

  const toggleModal = useCallback(() => {
    setShowLabelSelectionModal((show) => !show)
  }, [])

  const onAddReserveChange = () => {
    if (labelsCount > 1 || auditTypesCount > 1) {
      toggleModal()
    } else if (labelsCount === 1) {
      history.push(
        `/audits/${auditData.id}/types/${auditData.types[0].id}/labels/${auditData.labels[0].id}/ramounts/new`
      )
    }
  }

  const reservedAmountsList = getCalculationDetails(
    auditReserves?.audit?.reservedAmounts
  )

  return (
    <div className={styles.container}>
      <LabelSelectionModal
        defaultValues={defaultValues}
        auditData={auditData}
        onClose={toggleModal}
        show={showLabelSelectionModal}
      />
      <header className={styles.header}>
        <div>
          <h4>Reserve Calculations</h4>
          <p>Below are all the current reserves you have added to this audit</p>
        </div>
        <div className={styles['button-wrapper']}>
          {!labelsCount && (
            <Button
              disabled
              secondaryIcon="exclamation"
              label="Add Labels to Audit"
            />
          )}
          {!!labelsCount && (
            <Button
              onClick={onAddReserveChange}
              secondaryIcon="plus"
              label="Add Reserve Change"
            />
          )}
        </div>
      </header>
      <Table columns={columns} data={reservedAmountsList || []}></Table>
    </div>
  )
}

export default Reserves

import { FC, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import ReserveForm from '../ReserveForm/ReserveForm'
import ReservesHeader from '../ReservesHeader/ReservesHeader'
import styles from './styles.module.scss'
import {
  AuditDocument,
  useAuditQuery,
  useReservedAmountsHistoryQuery,
  useUpdateReservedAmountMutation,
} from 'generated/graphql'
import notify from 'utils/notify'
import { Notification } from 'constants/typeConstants'
import { isEmpty } from 'lodash'
import Spinner from 'components/Spinner/Spinner'
import {
  createRAmountObject,
  getCalculationDetails,
  reserveCalculations,
} from 'utils/reserveCalculations'
import { IReserveForm } from 'interfaces/ReserveFormInterface'

interface IBreadcrumbs {
  name: string
  to?: string
}

const UpdateReserveCalc: FC = () => {
  const history = useHistory()
  const { auditId, labelId, auditTypeId, ramountId } = useParams<{
    auditId: string
    labelId: string
    auditTypeId: string
    ramountId: string
  }>()
  const [defaultValues, setDefaultValues] = useState({})
  const [ramount, setRamount] = useState<string | undefined | null>()
  const [auditOfferId, setAuditOfferId] = useState<any>()
  const [updateReserveAmount] = useUpdateReservedAmountMutation()

  const convertToDate = (date: string | null) => {
    const newDate = date ? new Date(date) : null
    return newDate
  }

  const { data, loading } = useReservedAmountsHistoryQuery({
    variables: { auditLabelId: labelId, auditTypeId },
    onCompleted() {
      const reserveForUpdate = data?.reservedAmountsHistory?.find(
        (r) => r?.id === ramountId
      )

      const details = getCalculationDetails([reserveForUpdate], true)
      setAuditOfferId(
        JSON.parse(reserveForUpdate?.calculationDetails)?.auditOfferId
      )
      setDefaultValues({
        additionalAmount: details?.[0].additionalAmount,
        earnings: details?.[0].earnings,
        rate: details?.[0].rate,
        fpp: details?.[0].fpp,
        otherAmount: details?.[0].otherAmount,
        previousAmount: details?.[0].previousAmount,
        reasonId: reserveForUpdate?.reason?.id,
        reservedDate: convertToDate(reserveForUpdate?.reservedDate),
      })
      setRamount(reserveForUpdate?.amount)
    },
    onError() {
      notify(
        Notification.ERROR,
        'There is some error while loading the Reserves data'
      )
    },
    fetchPolicy: 'cache-and-network',
  })

  const breadcrumbs: IBreadcrumbs[] = [
    { name: 'Home', to: '/' },
    { name: 'My Audits', to: '/audits' },
    { name: 'Audit', to: `/audits/${auditId}` },
    {
      name: 'Reserves',
      to: `/audits/${auditId}/types/${auditTypeId}/labels/${labelId}/ramounts`,
    },
    { name: 'Edit Reserve' },
  ]

  const { data: auditData } = useAuditQuery({
    variables: { input: auditId! },
    onError() {
      notify(
        Notification.ERROR,
        'There is some error while loading the Audits data'
      )
    },
  })

  const selectedLabel = auditData?.audit?.labels?.find(
    (label) => label?.id === labelId
  )

  const selectedSubcategory = auditData?.audit?.types?.find(
    (type) => type?.id === auditTypeId
  )

  const onSubmit = (data: IReserveForm) => {
    const amount =
      reserveCalculations(data.reasonId, data, data.previousAmount) || ''
    let calculationDetailsObj = { ...data }
    if (auditOfferId) calculationDetailsObj = { ...data, auditOfferId }
    const ramount = createRAmountObject(calculationDetailsObj)
    const calculationDetails = JSON.stringify(ramount)

    const dataForUpdate = {
      id: ramountId,
      amount,
      calculationDetails,
      notes: data.notes || '',
    }

    updateReserveAmount({
      variables: { input: dataForUpdate },
      refetchQueries: [
        {
          query: AuditDocument,
          variables: { input: auditId },
        },
      ],
    })
      .then(() => {
        history.goBack()
        notify(Notification.SUCCESS, 'Reserve was successfully updated')
      })
      .catch(() => {
        notify(Notification.ERROR, 'There is some error while updating reserve')
      })
  }

  return (
    <>
      <ReservesHeader breadcrumbs={breadcrumbs} />
      <div className={styles.container}>
        <h3>
          Edit Reserve Calculation for {selectedLabel?.name} /{' '}
          {selectedSubcategory?.name}, audit {auditData?.audit?.name}{' '}
          (NOI:&nbsp;
          {auditData?.audit?.noticeDate || 'N/A'})
        </h3>
        <hr className="divider" />
        {!isEmpty(defaultValues) && (
          <ReserveForm
            onSubmit={onSubmit}
            defaultValues={defaultValues}
            ramount={ramount}
          />
        )}
        {(loading || isEmpty(defaultValues)) && <Spinner name="circle-notch" />}
      </div>
    </>
  )
}

export default UpdateReserveCalc

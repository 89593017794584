import { FC, Suspense } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Accordion, InputGroup, Textarea } from '@wmgtech/legato'

import FormDatepicker from 'components/Form/FormDatepicker'
import Spinner from 'components/Spinner'
import LoadingButton from 'components/LoadingButton'
import OfferAllocations from '../OfferAllocations'
import styles from './styles.module.scss'

const OfferForm: FC<any> = ({
  defaultValues,
  onSubmit,
  offersData,
  claims,
}) => {
  const methods = useForm({ defaultValues: defaultValues })
  const { register } = methods

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
        <Accordion
          className={styles.accordion}
          list={[
            {
              title: 'Offer Details',
              content: (
                <Suspense fallback={<Spinner name="circle-notch" />}>
                  <div className={styles.offer}>
                    <div className="col-md-6">
                      <FormDatepicker
                        placeholder="Select a Date"
                        name="offerDate"
                        label="Offer Date"
                        initialDate={defaultValues?.offerDate || null}
                        rules={{ required: true }}
                        message="Offer date can't be blank"
                      />
                    </div>
                    <InputGroup label="Offer Notes">
                      <Textarea
                        {...register('notes')}
                        name="notes"
                        placeholder="Please leave a note regarding this offer"
                      />
                    </InputGroup>{' '}
                  </div>
                </Suspense>
              ),
              id: 0,
              expand: false,
            },
          ]}
        />
        <div className="divider"></div>
        <OfferAllocations data={offersData ?? []} claims={claims ?? []} />
        <footer className={styles.footer}>
          <LoadingButton
            type="submit"
            containerStyle={'outline'}
            colorType={'primary'}
          >
            Update Offer
          </LoadingButton>
        </footer>
      </form>
    </FormProvider>
  )
}

export default OfferForm

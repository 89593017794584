import { Button } from '@wmgtech/legato'
import { FC } from 'react'
import { NavLink, Link, useParams } from 'react-router-dom'

import AuditTabs from './AuditTabs'
import { useAuditQuery } from 'generated/graphql'
import notify from 'utils/notify'
import { Notification } from 'constants/typeConstants'
import convertDateToString from 'utils/convertDateToString'
import currencyFormat from 'utils/currencyFormat'
import styles from './styles.module.scss'

const Audit: FC = () => {
  const { id } = useParams<{ id?: string }>()

  const { data } = useAuditQuery({
    variables: { input: id! },
    onError() {
      notify(
        Notification.ERROR,
        'There is some error while loading the Audits data'
      )
    },
  })

  const lastActivity = [...((data?.audit?.activities as any[]) || [])]
    .filter((a) => a.actualDate)
    .sort((a, b) => (b?.actualDate).localeCompare(a?.actualDate))[0]

  const getCatSubcat = () => {
    return data?.audit?.types?.map((type) => type?.name).join(', ')
  }

  return (
    <div>
      <header className={styles.header}>
        <h2>Audit Detail</h2>
        <ul className="breadcrumbs">
          <li>
            <NavLink className="breadcrumbs-item" to={'/'}>
              Home
            </NavLink>
          </li>{' '}
          <li>
            <NavLink className="breadcrumbs-item" to={'/audits'}>
              My Audits
            </NavLink>
          </li>
          <li>
            <strong>Audit details</strong>
          </li>
        </ul>
      </header>
      <div className={styles.container}>
        <header className={styles['audit-header']}>
          <h2>{data?.audit?.name ?? 'Audit Name'}</h2>
          <div className={styles.activities}>
            <Button
              containerStyle={'outline'}
              colorType={'warning'}
              secondaryIcon="edit"
              size="sm"
            >
              <Link
                className={styles.edit}
                to={{ pathname: `/audits/${id}/edit` }}
              >
                Edit Audit
              </Link>
            </Button>
            <Button
              containerStyle={'outline'}
              colorType={'danger'}
              secondaryIcon="trash-can"
              size="sm"
            >
              Delete Audit
            </Button>
          </div>
        </header>
        <main>
          <div className={styles.summary}>
            <div>
              <div className="row mb-05">
                <div className="col-sm-4 bold">Status:</div>
                <div className="col-sm-8">{data?.audit?.status.name}</div>
              </div>
              <div className="row mb-05">
                <div className="col-sm-4 bold">Last Activity:</div>
                <div className="col-sm-8">
                  {lastActivity?.type.activityNumber} |{' '}
                  {lastActivity?.type.name ?? 'Audit Setup'} (
                  {lastActivity?.actualDate ??
                    lastActivity?.manuallyProjectedDate ??
                    convertDateToString(data?.audit?.createdAt)}
                  )
                </div>
              </div>
              <div className="row mb-05">
                <div className="col-sm-4 bold">Current claims:</div>
                <div className="col-sm-8">
                  {currencyFormat(Number(data?.audit?.claimsSum ?? 0))}
                </div>
              </div>
              <div className="row mb-05">
                <div className="col-sm-4 bold">Current offers:</div>
                <div className="col-sm-8">
                  {currencyFormat(Number(data?.audit?.offerSum ?? 0))}
                </div>
              </div>
            </div>
            <div>
              <div className="row mb-05">
                <div className="col-sm-4 bold">External auditor:</div>
                <div className="col-sm-8">
                  {data?.audit?.auditor?.name ?? 'No Auditor Assigned'}
                </div>
              </div>
              <div className="row mb-05">
                <div className="col-sm-4 bold">Category|Sub:</div>
                <div className="col-sm-8">{getCatSubcat() ?? 'N/A'}</div>
              </div>
              <div className="row mb-05">
                <div className="col-sm-4 bold">Parent Audit:</div>
                <div className="col-sm-8">
                  {data?.audit?.parent?.name ?? 'No Parent Audit'}
                </div>
              </div>
              <div className="row mb-05">
                <div className="col-sm-4 bold">Related Artist:</div>
                <div className="col-sm-8">
                  {data?.audit?.artist?.name ?? 'No Artist Selected'}
                </div>
              </div>
            </div>
            <div>
              <div className="row mb-05">
                <div className="col-sm-6 bold">Notice Date(NOI):</div>
                <div className="col-sm-6">
                  {data?.audit?.noticeDate ?? 'N/A'}
                </div>
              </div>
              <div className="row mb-05">
                <div className="col-sm-6 bold">Audit Period Start:</div>
                <div className="col-sm-6">
                  {data?.audit?.startDate ?? 'N/A'}
                </div>
              </div>
              <div className="row mb-05">
                <div className="col-sm-6 bold">Audit Period End:</div>
                <div className="col-sm-6">{data?.audit?.endDate ?? 'N/A'}</div>
              </div>
              <div className="row mb-05">
                <div className="col-sm-6 bold">Settlement Date:</div>
                <div className="col-sm-6">N/A</div>
              </div>
            </div>
          </div>
          <div className="dashes"></div>
          <AuditTabs auditData={data?.audit} />
        </main>
      </div>
    </div>
  )
}

export default Audit

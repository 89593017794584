import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Button, Icon, InputGroup, Select, Table } from '@wmgtech/legato'

import { IBreadcrumbs } from 'interfaces/BreadCrumbsInterface'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import LoadingButton from 'components/LoadingButton/LoadingButton'
import AddNewRateModal from './AddNewRate/AddNewRateModal'
import notify from 'utils/notify'
import { Notification } from 'constants/typeConstants'
import {
  RatesDocument,
  RatesQuery,
  useCreateRateMutation,
  useDeleteRatesMutation,
  useLastDefaultResAmountsLazyQuery,
  useRatesQuery,
} from 'generated/graphql'
import useData from 'hooks/useData'
import SubmissionModal from 'components/SubmissionModal'
import RateReservesTable from './ReservesTable/RateReservesTable'
import styles from './styles.module.scss'

const MOCK_FISKAL_YEAR_LIST = [{ key: '2023', title: '2023' }]
const MOCK_YEAR = '2023'

const Rates: FC = () => {
  const columns = [
    {
      title: 'Audit Subcategory',
      dataIndex: 'auditSubcategory',
      key: 'auditSubcategory',
      render: (value: string, row: any) => row.auditSubcategory.name,
    },
    {
      title: 'Labels',
      dataIndex: 'labels',
      key: 'labels',
      render: (value: string, row: any) =>
        row.labels.map((l: any) => l.name).join(', '),
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'editable',
      render: (value: string, row: any) => {
        return (
          <div>
            <Button
              onClick={() => {
                getLastDefaultReservesList(row)
                setRateSelected(true)
                setRate(row)
                setSelectedRow({
                  subcategory: row.auditSubcategory.name,
                  rate: row.rate,
                })
              }}
              containerStyle={'link'}
              colorType={'success'}
              className={styles.button}
            >
              <Icon name="circle-check" prefix="fas" />
            </Button>
            <span className={styles.separator}>|</span>
            <Button
              onClick={() => {
                setRate(row)
                toggleSubmissionModal()
              }}
              containerStyle={'link'}
              colorType={'danger'}
              className={styles.button}
            >
              <Icon name="trash" prefix="fas" />
            </Button>
          </div>
        )
      },
    },
  ]
  const breadcrumbs: IBreadcrumbs[] = [
    { name: 'Home', to: '/' },
    { name: 'Admin Controls', to: '/home/admin_controls' },
    { name: 'Rates' },
  ]

  const [rate, setRate] = useState({
    auditSubcategory: { id: '' },
    labels: [{ id: '' }],
  })
  const [isRateSelected, setRateSelected] = useState(false)
  const [selectedRow, setSelectedRow] = useState({
    subcategory: '',
    rate: '',
  })
  const [defRateResList, setDefRateList] = useState<any>()
  const [showCreationalModal, setShowCreationalModal] = useState(false)
  const [showSubmissionModal, setShowSubmissionModal] = useState(false)
  const [minDate, setMinDate] = useState<Date>()
  const [createRate] = useCreateRateMutation()
  const [deleteRatesFunc] = useDeleteRatesMutation()
  const [getLastDefReservesList] = useLastDefaultResAmountsLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted(response) {
      if (
        Array.isArray(response?.lastDefaultReservedAmount) &&
        response?.lastDefaultReservedAmount.length
      ) {
        const reserves = []
        let lastResDate
        for (let reserve of response?.lastDefaultReservedAmount) {
          reserves.push({ ...reserve, key: reserve?.id })
          if (!lastResDate) lastResDate = reserve?.reservedDate
          if (reserve?.reservedDate.localeCompare(lastResDate) === 1) {
            lastResDate = reserve?.reservedDate
          }
        }
        const minDate = new Date(
          new Date(lastResDate).setDate(new Date(lastResDate).getDate()! + 1)
        )

        setMinDate(minDate)
        setDefRateList(reserves)
        return
      }
      setDefRateList([])
    },
  })
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  })

  const toggleCreationModal = useCallback(() => {
    setShowCreationalModal((show) => !show)
  }, [])

  const toggleSubmissionModal = useCallback(() => {
    setShowSubmissionModal((show) => !show)
  }, [])

  const { loading, previousData, data } = useRatesQuery()
  const ratesData = useData<RatesQuery>({ loading, previousData, data })

  const ratesForFY = ratesData?.rates
  const rates = []
  if (ratesForFY?.length) {
    for (let i = 0; i < ratesForFY?.length; i++) {
      rates.push({ ...ratesForFY[i], key: i })
    }
  }

  const getLastDefaultReservesList = (row: any) => {
    const auditSubcategoryId = row.auditSubcategory.id
    const labelIds = row.labels.map((l: { id: string }) => l.id)
    getLastDefReservesList({
      variables: {
        auditSubcategoryId,
        labelIds,
      },
    })
  }

  const addNewRate = (data: any) => {
    data.labelIds = data.labelIds.map((l: { key: string }) => l.key)

    createRate({
      variables: { input: data },
      refetchQueries: [
        {
          query: RatesDocument,
          variables: { input: data },
        },
      ],
    })
      .then(() => {
        mounted.current && toggleCreationModal()
        setRateSelected(false)
        notify(Notification.SUCCESS, 'Rate was successfully created')
      })
      .catch(() => {
        notify(Notification.ERROR, 'There is some error while creating rate')
      })
  }

  const deleteRates = () => {
    const auditSubcategoryId = rate.auditSubcategory.id
    const labelIds = rate.labels.map((l) => l.id)
    deleteRatesFunc({
      variables: {
        input: { auditSubcategoryId, labelIds },
      },
      refetchQueries: [
        {
          query: RatesDocument,
          variables: { input: data },
        },
      ],
    })
      .then(() => {
        setRateSelected(false)
        mounted.current && toggleSubmissionModal()
        notify(Notification.SUCCESS, 'You have deleted rates')
      })
      .catch(() => {
        notify(Notification.ERROR, 'There is some error while deleting rates')
      })
  }
  return (
    <>
      <AddNewRateModal
        show={showCreationalModal}
        onClose={toggleCreationModal}
        onSubmit={addNewRate}
      ></AddNewRateModal>
      <SubmissionModal
        name="rates"
        show={showSubmissionModal}
        onClose={toggleSubmissionModal}
        onSubmit={deleteRates}
      ></SubmissionModal>
      <div>
        <header className={styles.header}>
          <h2>Rates</h2>
          <div className={styles.buttons}>
            <Breadcrumbs links={breadcrumbs} />
          </div>
        </header>
        <div className={styles.container}>
          <div className={styles.selector}>
            <InputGroup label="Select Fiscal Year" className="col-md-3">
              <Select
                name="fiscalYear"
                list={MOCK_FISKAL_YEAR_LIST}
                value={MOCK_YEAR}
              ></Select>
            </InputGroup>
            <LoadingButton
              onClick={toggleCreationModal}
              icon="plus"
              className="col-md-2"
            >
              Add New Rate
            </LoadingButton>
          </div>

          <Table
            columns={columns}
            data={rates || []}
            className={styles.table}
          />
          {isRateSelected && (
            <RateReservesTable
              rateRowSelected={selectedRow}
              list={defRateResList}
              minDate={minDate}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default Rates

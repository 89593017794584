import { FC } from 'react'

import Breadcrumbs from 'components/Breadcrumbs'
import styles from './styles.module.scss'

const ReservesHeader: FC<any> = ({ breadcrumbs, auditData }) => {
  return (
    <header className={styles.header}>
      <div className="col-lg-5">
        <h2>Reserves</h2>
        <Breadcrumbs links={breadcrumbs} />
      </div>
    </header>
  )
}

export default ReservesHeader

import React, { FC, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Spinner from 'components/Spinner'
import styles from './styles.module.scss'
import notify from 'utils/notify'
import { Notification } from 'constants/typeConstants'
import { isEmpty } from 'lodash'
import ActivityTypeForm from '../ActivityTypeForm';
import {
  ActivityTypeDocument, GetActivityTypesDocument,
  useActivityTypeQuery,
  useUpdateActivityTypeMutation
} from '../../../generated/graphql';

const EditActivityType: FC = () => {
  const [defaultValues, setDefaultValues] = useState({})
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const [updateActivityType, { loading: updating }] = useUpdateActivityTypeMutation()

  const { data, loading } = useActivityTypeQuery({
    variables: { id: id! },
    onCompleted() {
      setDefaultValues({
        id: data?.auditActivityType?.id,
        name: data?.auditActivityType?.name,
        description: data?.auditActivityType?.description,
        category: data?.auditActivityType?.category,
        reportCategory: data?.auditActivityType?.reportCategory,
        responsibility: data?.auditActivityType?.responsibility,
        auditStatus: data?.auditActivityType?.auditStatus,
        isStatus: data?.auditActivityType?.isStatus,
        isKpi: data?.auditActivityType?.isKpi,
        order: data?.auditActivityType?.order,
        activityNumber: data?.auditActivityType?.activityNumber,
        statusNumber: data?.auditActivityType?.statusNumber
      })
    },
    onError() {
      notify(
        Notification.ERROR,
        'There is some error while loading the Activity Type data'
      )
    }
  })

  if (loading || isEmpty(defaultValues)) {
    return <Spinner name="circle-notch"/>
  }

  const updateActivityTypeFunc = (data: any) => {
    updateActivityType({
      variables: { input: { ...data, id } },
      update(cache: any, MutationResult: any) {
        cache.writeQuery({
          query: ActivityTypeDocument,
          variables: {
            id
          },
          data: {
            auditActivityType: {
              ...MutationResult.data?.updateAuditActivityType
            }
          }
        })
      },
      refetchQueries:[{
        query: GetActivityTypesDocument
      }]
    }).then(() => history.push('/activity-types'))
      .catch(() => notify(Notification.ERROR, 'There is some error while updating the Activity Type'))
  }

  return (
    <div>
      <header className={styles.header}>
        <h2>Activity Type: {data?.auditActivityType?.name}</h2>
      </header>
      <ActivityTypeForm
        action={'update'}
        defaultValues={defaultValues}
        onSubmit={updateActivityTypeFunc}
        loading={updating}
      />
    </div>
  )
}

export default EditActivityType

import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'

import Breadcrumbs from 'components/Breadcrumbs'
import HeaderRowInfo from 'components/HeaderRowInfo'
import { IBreadcrumbs } from 'interfaces/BreadCrumbsInterface'
import {
  AuditDocument,
  useAuditOffersQuery,
  useAuditQuery,
  useUpdateAuditOfferMutation,
} from 'generated/graphql'
import Spinner from 'components/Spinner'
import OfferForm from '../OfferForm'
import convertDateToString from 'utils/convertDateToString'
import { Notification } from 'constants/typeConstants'
import notify from 'utils/notify'
import styles from './styles.module.scss'

const convertToDate = (date: string | null) => {
  const newDate = date ? new Date(date) : null
  return newDate
}

const Offer: FC = () => {
  const { auditId, offerId } = useParams<{ auditId: string; offerId: string }>()
  const breadcrumbs: IBreadcrumbs[] = [
    { name: 'Home', to: '/' },
    { name: 'Audits', to: '/audits' },
    { name: 'Audit', to: `/audits/${auditId}` },
    { name: 'Offer Details' },
  ]

  const [defaultValues, setDefaultValues] = useState<any>({})
  const [offer, setOffer] = useState<any>()
  const [claims, setClaims] = useState<any>()

  const { data: auditData } = useAuditQuery({
    variables: { input: auditId! },
  })

  const [updateAuditOffer] = useUpdateAuditOfferMutation()

  const { loading } = useAuditOffersQuery({
    variables: { auditId },
    fetchPolicy: 'no-cache',
    onCompleted(response) {
      const offer = response.auditOffers.find((o) => o.id === offerId)
      const newData = offer?.allocations?.map((d: any) => {
        return {
          id: d.auditClaim.id,
          name: d.auditClaim.name,
          number: d.auditClaim.number,
          amount: d.auditClaim.amount,
          auditType: d.auditClaim.auditType,
          allocations: [{ amount: d.amount, label: d.auditLabel }],
        }
      })

      const claims: any = []
      if (newData) {
        for (let i of newData) {
          if (claims.find((cl: any) => cl.name === i.name)) {
            claims
              .find((cl: any) => cl.name === i.name)
              .allocations.push(...i.allocations)
          } else claims.push(i)
        }
        setOffer(offer)
        setClaims(claims)
        setDefaultValues({
          offerDate: convertToDate(offer?.offerDate),
          notes: offer?.notes,
          claims,
        })
      }
    },
  })

  const onSubmit = (data: any) => {
    const allocations = []
    for (let claim of data.claims) {
      for (let alloc of claim.allocations) {
        const allocation = {
          flatAmount: alloc.amount.flat,
          advancedAmount: alloc.amount.advanced,
          otherAmount: alloc.amount.other,
          notes: alloc.notes,
          auditLabelId: alloc.label.id,
          auditClaimId: claim.id,
        }
        allocations.push(allocation)
      }
    }

    const input = {
      id: offerId,
      offerDate: convertDateToString(data.offerDate),
      notes: data.notes,
      allocations,
    }

    updateAuditOffer({
      variables: { input },
      refetchQueries: [
        {
          query: AuditDocument,
          variables: { input: auditId },
        },
      ],
      onCompleted() {
        notify(Notification.SUCCESS, 'Your offer was updated!')
      },
      onError() {
        notify(Notification.ERROR, 'There is some error while updating offer')
      },
    })
  }

  return (
    <>
      <header className={styles.header}>
        <div className="col-lg-5">
          <h2>Offer Details</h2>
          <Breadcrumbs links={breadcrumbs} />
        </div>
        <HeaderRowInfo auditData={auditData} />
      </header>
      <div className={styles.container}>
        <div className="row">
          <h2 className="col-md-6">Offer with Allocations</h2>
        </div>
        <div className="divider"></div>
        {loading && <Spinner name="circle-notch" />}
        {!isEmpty(defaultValues) && (
          <OfferForm
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            offersData={offer}
            claims={claims}
          />
        )}
      </div>
    </>
  )
}

export default Offer

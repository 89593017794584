import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'
import dayjs from 'dayjs'
import { Accordion } from '@wmgtech/legato'

import Table from 'components/Table'
import // AuditDashboardFieldName,
// FilterPeriod,
// GetAuditDashboardListQuery,
// OrderDirection,
// useGetAuditDashboardListQuery,
'generated/graphql'
import currencyFormat from 'utils/currencyFormat'
// import useData from 'hooks/useData'
// import useGlobalProgress from 'hooks/useGlobalProgress'
// import notify from 'utils/notify'
// import { Notification } from 'constants/typeConstants'
import styles from './styles.module.scss'

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '150px',
    sortable: true,
    className: `${styles.table}`,
    render: (value: string, row: any) => {
      return <Link to={`/audits/${row.id}`}>{value}</Link>
    },
  },
  {
    title: 'Subcategory',
    dataIndex: 'subCategories',
    key: 'subcategory',
    sortable: true,
    ellipsis: true,
    className: `${styles.table}`,
  },
  {
    title: 'Audit Period',
    dataIndex: 'auditPeriod',
    key: 'auditPeriod',
    sortable: true,
    render: (value: string, row: any) => {
      const format = 'MM/YY'
      return `${dayjs(row.startPeriod).format(format)} - ${dayjs(
        row.endPeriod
      ).format(format)}`
    },
  },
  {
    title: 'Beg Bal',
    dataIndex: 'begBal',
    key: 'begBal',
    sortable: true,
    render: (value: string, row: any) => currencyFormat(row.beginningBalance),
  },
  {
    title: 'PL Impact',
    dataIndex: 'plImpact',
    key: 'plImpact',
    sortable: true,
    render: (value: string, row: any) => currencyFormat(row.plImpactBalance),
  },
  {
    title: 'Payments',
    dataIndex: 'payments',
    key: 'payments',
    sortable: true,
    render: (value: string, row: any) => currencyFormat(row.paymentBalance),
  },
  {
    title: 'Ending Bal',
    dataIndex: 'endingBal',
    key: 'endingBal',
    sortable: true,
    render: (value: string, row: any) => currencyFormat(row.endingBalance),
  },
]

type AuditsTableProps = {
  categories: (string | null)[]
  labelMasters: (string | null)[]
  labelMastersNames: string[]
  period: any //FilterPeriod
}

const AuditsTable: FC<AuditsTableProps> = ({
  categories,
  labelMasters,
  labelMastersNames,
  period,
}) => {
  // const search = ''
  // const sortOrder = {
  //   field: AuditDashboardFieldName.Name,
  //   direction: OrderDirection.Asc,
  // }
  // const limit = 10
  // const offset = 0
  const header = isEmpty(labelMastersNames)
    ? 'all label'
    : labelMastersNames.join(', ')

  // const auditDashboardListQuery = useGetAuditDashboardListQuery({
  //   variables: {
  //     input: {
  //       categories,
  //       labelMasters,
  //       search,
  //       limit,
  //       offset,
  //       sortOrder,
  //       period,
  //     },
  //   },
  //   onError() {
  //     notify(
  //       Notification.ERROR,
  //       'There is some error while loading the audits data'
  //     )
  //   },
  // })
  // const auditDashboardListData = useData<GetAuditDashboardListQuery>({
  //   loading: auditDashboardListQuery.loading,
  //   previousData: auditDashboardListQuery.previousData,
  //   data: auditDashboardListQuery.data,
  // })

  // useGlobalProgress(auditDashboardListQuery.loading)

  return (
    <Accordion
      className={styles.accordion}
      list={[
        {
          title: `All Audits for ${header}`,
          content: (
            <Table
              responsive
              columns={columns}
              data={[]}
              //data={auditDashboardListData?.getAuditDashboardList?.items ?? []}
            />
          ),
          id: 0,
          expand: false,
        },
      ]}
    ></Accordion>
  )
}

export default AuditsTable

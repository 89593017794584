export const calculateAllocations = (labels: any[], reservesTotal: any, claim: any): any[] => {
  const allocations: any = []

  const reserve = reservesTotal
    .find((res: any) => claim.auditTypeId === res.auditTypeId)

  for (let label of reserve.labels) {
    const amount = label ? (label.part / 100) * claim.amount : 0
    const auditLabel = labels.find((l: any) => l.id === label.auditLabelId)
    const allocation = {
      auditLabelId: label.auditLabelId,
      auditLabelName: auditLabel.abbreviation,
      amount: +amount.toFixed(2),
      notes: '',
      part: label ? label.part : '0.00',
    }
    allocations.push(allocation)
  }
  return allocations
}

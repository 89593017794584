import React, { FC, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ArrayParam, useQueryParams, withDefault } from 'use-query-params'
import { QueryParamConfig } from 'serialize-query-params/lib/types'
import { uniq } from 'lodash'

import makeButtonFilterLabels from 'utils/makeButtonFilterLabels'
import makeActiveLabels from 'utils/makeActiveLabels'
import {
  useLabelsListQuery,
  LabelsListQuery,
  useAuditCategoriesListQuery,
  AuditCategoriesListQuery,
} from 'generated/graphql'
import useGlobalProgress from 'hooks/useGlobalProgress'
import useData from 'hooks/useData'
import AuditAccordion from './components/AuditAccordion'
import ButtonFilter from 'components/ButtonFilter'
import LoadingButton from 'components/LoadingButton'
import styles from './styles.module.scss'

const CurrentAuditsTable = React.lazy(
  () => import('./components/CurrentAuditsTable')
)
const ClosedAuditTable = React.lazy(
  () => import('./components/ClosedAuditTable')
)
const OpenPeriodTable = React.lazy(() => import('./components/OpenPeriodTable'))

type QueryParams = {
  labelMasters: QueryParamConfig<(string | null)[]>
  categories: QueryParamConfig<(string | null)[]>
}

const Audits: FC = () => {
  const history = useHistory()
  const [query, setQuery] = useQueryParams<QueryParams>({
    labelMasters: withDefault(ArrayParam, []),
    categories: withDefault(ArrayParam, []),
  })

  const [isCurrentExpanded, setExpanded] = useState(false)

  const getLabelMasterListForFiltrationQuery = useLabelsListQuery()
  const masterListForFiltrationData = useData<LabelsListQuery>({
    loading: getLabelMasterListForFiltrationQuery.loading,
    previousData: getLabelMasterListForFiltrationQuery.previousData,
    data: getLabelMasterListForFiltrationQuery.data,
  })

  const getCategoryListQuery = useAuditCategoriesListQuery()
  const categoryListData = useData<AuditCategoriesListQuery>({
    loading: getCategoryListQuery.loading,
    previousData: getCategoryListQuery.previousData,
    data: getCategoryListQuery.data,
  })

  const masterListForFiltrationLabels = useMemo(() => {
    const items = masterListForFiltrationData?.labels?.items

    if (items) {
      return makeButtonFilterLabels(items)
    }

    return null
  }, [masterListForFiltrationData?.labels?.items])

  const handleLabelsClear = () => {
    setQuery({
      ...query,
      labelMasters: [],
    })
  }

  const handleLabelSelect = (id: string) => {
    const labelMasters = makeActiveLabels(query.labelMasters, id)

    setQuery({
      labelMasters,
    })
  }

  const categoryListLabels = useMemo(() => {
    const items = categoryListData?.auditCategories

    if (items) {
      return makeButtonFilterLabels(items)
    }

    return null
  }, [categoryListData?.auditCategories])

  const handleCategoryClear = () => {
    setQuery({
      ...query,
      categories: [],
    })
  }

  const handleCategorySelect = (id: string) => {
    const categories = makeActiveLabels([], id) as string[]
    setQuery({
      categories,
    })
  }

  const handleAddNewAudit = () => {
    history.push('/audits/new')
  }

  useGlobalProgress(
    getLabelMasterListForFiltrationQuery.loading || getCategoryListQuery.loading
  )

  return (
    <>
      <div className={styles.header}>
        <div className={styles.item}>
          {masterListForFiltrationLabels && (
            <ButtonFilter
              title="Select a Label:"
              onClear={handleLabelsClear}
              onSelect={handleLabelSelect}
              labels={masterListForFiltrationLabels || []}
              activeLabels={uniq(query.labelMasters.map(Number))}
            />
          )}
        </div>
        <div className={styles.item}>
          {categoryListLabels && (
            <ButtonFilter
              title="Select a Category:"
              onClear={handleCategoryClear}
              onSelect={handleCategorySelect}
              labels={categoryListLabels || []}
              activeLabels={uniq(query.categories.map(Number))}
            />
          )}
        </div>
        <div className={styles.item}>
          <LoadingButton onClick={handleAddNewAudit} icon="plus">
            Add New Audit
          </LoadingButton>
        </div>
      </div>
      <div className={styles.row}>
        <AuditAccordion
          expand={isCurrentExpanded}
          title="Current Audits"
          id={0}
        >
          <CurrentAuditsTable
            setParentExpanded={setExpanded}
            categories={query.categories}
            labelMasters={query.labelMasters}
          />
        </AuditAccordion>
      </div>
      <div className={styles.row}>
        <AuditAccordion expand={false} title="Open Period/Advances" id={1}>
          <OpenPeriodTable
            categories={query.categories}
            labelMasters={query.labelMasters}
          />
        </AuditAccordion>
      </div>
      <div className={styles.row}>
        <AuditAccordion expand={false} title="Settled/Closed Audits" id={2}>
          <ClosedAuditTable
            categories={query.categories}
            labelMasters={query.labelMasters}
          />
        </AuditAccordion>
      </div>
    </>
  )
}

export default Audits

import { FC, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Datepicker, InputGroup } from '@wmgtech/legato'

import FormDatepicker from 'components/Form/FormDatepicker/FormDatepicker'
import {
  GetActivityTypesQuery,
  useGetActivityTypesQuery,
} from 'generated/graphql'
import useData from 'hooks/useData'
import convertToNestedList from 'utils/convertToNestedList'
import convertDateToString from 'utils/convertDateToString'
import LoadingButton from 'components/LoadingButton'
import FormNestedList from 'components/Form/FormNestedList'
import styles from './styles.module.scss'

enum DATE_TYPE {
  ACTUAL = 'ACTUAL',
  PROJECTED = 'PROJECTED',
}

interface IActivityProps {
  defaultValues?: any
  auditId?: string | undefined | null
  selectedActivity?: any
  onClose: () => void
  onSubmit: (data: any) => void
}

const AuditActivityForm: FC<IActivityProps> = ({
  onSubmit,
  onClose,
  selectedActivity,
  defaultValues,
}) => {
  const [dateType, setDateType] = useState<DATE_TYPE>()
  const toggleDateInputs = (type: DATE_TYPE) => {
    setDateType(type)
    if (type === DATE_TYPE.ACTUAL) {
      setValue('manuallyProjectedDate', null)
    } else {
      setValue('actualDate', null)
    }
  }

  const { loading, previousData, data } = useGetActivityTypesQuery()
  const activityTypeListData = useData<GetActivityTypesQuery>({
    loading,
    previousData,
    data,
  })
  const auditActivities = activityTypeListData?.auditActivityTypes?.items
  const auditActivitiesList = convertToNestedList(auditActivities)

  const methods = useForm({ defaultValues: defaultValues })
  const { watch, setValue } = methods

  const watchActualDate = watch('actualDate')
  const watchProjDate = watch('manuallyProjectedDate')
  const isUpdate = defaultValues?.typeId

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
        <div className={styles.body}>
          <div className="row mb-2">
            <InputGroup label="Activity Name" className="col-md-6">
              <FormNestedList
                options={auditActivitiesList}
                name="typeId"
                placeholder="Select an activity"
              ></FormNestedList>
            </InputGroup>
            <div className={`col-md-6 ${styles.buttons}`}>
              {(dateType !== DATE_TYPE.ACTUAL ||
                (!dateType && defaultValues?.manuallyProjectedDate)) && (
                <Button onClick={() => toggleDateInputs(DATE_TYPE.ACTUAL)}>
                  I have an ACTUAL date
                </Button>
              )}
              {(dateType !== DATE_TYPE.PROJECTED ||
                (!dateType && defaultValues?.actualDate)) && (
                <Button
                  onClick={() => toggleDateInputs(DATE_TYPE.PROJECTED)}
                  colorType="success"
                >
                  I will PROJECT the date
                </Button>
              )}
            </div>
          </div>

          <div className="row form-group-three">
            {(dateType || (!dateType && isUpdate)) && (
              <>
                <div className="col-md-4">
                  {(dateType === DATE_TYPE.ACTUAL ||
                    (!dateType && defaultValues?.actualDate)) && (
                    <FormDatepicker
                      name="actualDate"
                      label="Actual Date"
                      initialDate={
                        isUpdate ? defaultValues?.actualDate || null : null
                      }
                    />
                  )}
                  {(dateType === DATE_TYPE.PROJECTED ||
                    (!dateType && defaultValues?.manuallyProjectedDate)) && (
                    <FormDatepicker
                      name="manuallyProjectedDate"
                      label="Manual Projection"
                      initialDate={
                        isUpdate
                          ? defaultValues?.manuallyProjectedDate || null
                          : null
                      }
                    />
                  )}
                </div>

                <div className="col-md-4">
                  <InputGroup label="Calculated Proj">
                    <Datepicker name="calc" initialDate={null} disabled />
                  </InputGroup>
                </div>
              </>
            )}
            {((watchActualDate && dateType === DATE_TYPE.ACTUAL) ||
              (watchProjDate && dateType === DATE_TYPE.PROJECTED)) && (
              <div className="col-md-4">
                <b>
                  The date to be saved is an <br />
                  {dateType} date of:{' '}
                  {dateType === DATE_TYPE.ACTUAL
                    ? convertDateToString(watchActualDate)
                    : convertDateToString(watchProjDate)}
                </b>
              </div>
            )}
          </div>
        </div>
        <footer className={styles.footer}>
          {onClose && (
            <LoadingButton
              containerStyle={'outline'}
              colorType={'secondary-black'}
              onClick={onClose}
            >
              Close
            </LoadingButton>
          )}
          <LoadingButton
            type="submit"
            containerStyle={'outline'}
            colorType={'primary'}
          >
            {isUpdate ? 'Update Activity' : 'Create Activity'}
          </LoadingButton>
        </footer>
      </form>
    </FormProvider>
  )
}

export default AuditActivityForm

import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Button, Icon, Table } from '@wmgtech/legato'
import useData from '../../hooks/useData'
import { ColumnsType } from '@wmgtech/legato/build/components/Table/types'
import { Link, useHistory } from 'react-router-dom'
import styles from './styles.module.scss'
import SubmissionModal from '../../components/SubmissionModal/SubmissionModal'
import notify from '../../utils/notify'
import { Notification } from '../../constants/typeConstants'
import Breadcrumbs from '../../components/Breadcrumbs'
import { IBreadcrumbs } from '../../interfaces/BreadCrumbsInterface'
import LoadingButton from '../../components/LoadingButton';
import {
  GetActivityTypesDocument,
  GetActivityTypesQuery,
  useDeleteActivityTypeMutation, useGetActivityTypesQuery,
} from '../../generated/graphql';

interface ITableItem {
  id: string
  name: string
  statusNumber: string
  activityNumber: string
  isStatus: string
  isKpi: string
}

const ActivityTypes: FC = () => {
  const history = useHistory()
  const [activityTypeId, setActivityTypeId] = useState('')
  const [showSubmissionModal, setShowSubmissionModal] = useState(false)
  const [deleteActivityType] = useDeleteActivityTypeMutation()
  const { loading, previousData, data } = useGetActivityTypesQuery()
  const activityTypeListData = useData<GetActivityTypesQuery>({ loading, previousData, data })
  const mounted = useRef(false)

  const toggleSubmissionModal = useCallback(() => {
    setShowSubmissionModal((show) => !show)
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  })

  const auditActivityTypes = activityTypeListData?.auditActivityTypes?.items

  const removeActivityType = () => {
    deleteActivityType({
      variables: { id: activityTypeId },
      update(cache: any, MutationResult: any) {
        const newActivityTypes = auditActivityTypes?.filter(
          (activityType) => activityType?.id !== MutationResult.data?.deleteAuditActivityType.id
        )
        cache.writeQuery({
          query: GetActivityTypesDocument,
          data: {
            auditActivityTypes: {
              total: newActivityTypes?.length,
              items: newActivityTypes,
            }
          },
        })
      }
    }).then(() => {
      mounted.current && toggleSubmissionModal()
      notify(Notification.SUCCESS, 'You have deleted an activity type')
    }).catch(() => {
      notify(
        Notification.ERROR,
        'There is some error while deleting an activity type'
      )
    })
  }



  const tableColumns: ColumnsType<any>[] = [
    {
      dataIndex: 'name',
      key: 'name',
      sortable: false,
      title: 'Activity Name'
    },
    {
      dataIndex: 'statusNumber',
      key: 'statusNumber',
      sortable: false,
      title: 'Status Number'
    },
    {
      dataIndex: 'isStatus',
      key: 'isStatus',
      sortable: false,
      title: 'Status?'
    },
    {
      dataIndex: 'isKpi',
      key: 'isKpi',
      sortable: false,
      title: 'KPI?'
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (value: string, row: ITableItem) => {
        return (
          <div>
            <Link to={`/activity-types/${row.id}/edit`}>
              <Icon name="edit" prefix="fas" />
            </Link>
            <span className={styles.separator}>|</span>
            <Button
              onClick={() => {
                setActivityTypeId(row.id)
                toggleSubmissionModal()
              }}
              containerStyle={'link'}
              colorType={'danger'}
              size="sm"
              className={styles.button}
            >
              <Icon name="trash" prefix="fas" />
            </Button>
          </div>
        )
      }
    }
  ]

  const tableData: ITableItem[] = auditActivityTypes?.map((activityType: any) => ({
    key: activityType?.id,
    id: activityType?.id,
    name: activityType?.name || 'N/A',
    statusNumber: activityType?.statusNumber || 'N/A',
    activityNumber: activityType?.activityNumber || 'N/A',
    isStatus: activityType?.isStatus ? 'Yes' : 'No',
    isKpi: activityType?.isKpi ? 'Yes' : 'No',
  })) || []

  const breadcrumbs: IBreadcrumbs[] = [
    { name: 'Home', to: '/' },
    { name: 'Admin Controls', to: '/home/admin_controls' },
    { name: 'Activity Types' }
  ]

  const handleAddNewActivityType = () => {
    history.push('/activity-types/new')
  }

  return (
    <div>
      <header className={styles.header}>
        <h2>Activity types</h2>
        <Breadcrumbs links={breadcrumbs} />
        <div className={styles.item}>
          <LoadingButton onClick={handleAddNewActivityType} icon="plus">
            Add New Activity type
          </LoadingButton>
        </div>
      </header>
      <SubmissionModal
        name="activity-type"
        show={showSubmissionModal}
        onClose={toggleSubmissionModal}
        onSubmit={removeActivityType}
      />
      {
        loading
          ? <p>loading...</p>
          : <Table
            condensed={true}
            pagination={{
            position: ['bottomCenter']
          }}
          columns={tableColumns}
          data={tableData}
          striped="none"
        />
      }
    </div>
  )
}

export default ActivityTypes

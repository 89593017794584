import { FC } from 'react'
import { InputGroup, Textarea } from '@wmgtech/legato'
import { FormProvider, useForm } from 'react-hook-form'

import LoadingButton from 'components/LoadingButton'
import styles from './styles.module.scss'

interface IActivityPayload {
  defaultValues: { comment: string }
  activity: any
  onClose: () => void
  onSubmit: (data: any) => void
}

const ActivityCommentForm: FC<IActivityPayload> = ({
  defaultValues,
  onClose,
  onSubmit,
  activity,
}) => {
  const methods = useForm({ defaultValues: defaultValues })
  const { register } = methods

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
        <div className={styles.body}>
          <InputGroup label="Comment">
            <Textarea
              {...register('comment')}
              name="comment"
              placeholder={`Comment on Activity: ${activity?.type?.activityNumber} | ${activity?.type?.name}`}
            />
          </InputGroup>
        </div>
        <footer className={styles.footer}>
          {onClose && (
            <LoadingButton
              containerStyle={'outline'}
              colorType={'secondary-black'}
              onClick={onClose}
            >
              Close
            </LoadingButton>
          )}
          <LoadingButton
            type="submit"
            containerStyle={'outline'}
            colorType={'primary'}
          >
            Create Activity Comment
          </LoadingButton>
        </footer>
      </form>
    </FormProvider>
  )
}

export default ActivityCommentForm

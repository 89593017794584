import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { ArtistsListDocument, useCreateArtistMutation } from 'generated/graphql';
import styles from './styles.module.scss'
import notify from 'utils/notify'
import { Notification } from 'constants/typeConstants'
import ArtistForm from '../ArtistForm'

const CreateArtist: FC = () => {
  const history = useHistory()

  const [createArtist, { loading }] = useCreateArtistMutation()

  const createArtistFunc = (data: any) => {
    createArtist({
      variables: { input: data },
      refetchQueries:[{
        query: ArtistsListDocument
      }]
    }).then(() => history.push('/artists'))
      .catch(() => notify(Notification.ERROR, 'There is some error while creating the artist'))
  }

  const defaultValues = {
    name: ''
  }

  return (
    <div>
      <header className={styles.header}>
        <h2>New Artist</h2>
      </header>
      <ArtistForm
        action={'create'}
        defaultValues={defaultValues}
        onSubmit={createArtistFunc}
        loading={loading}
      />
    </div>
  )
}

export default CreateArtist

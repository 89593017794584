import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Icon } from '@wmgtech/legato'

import { IBreadcrumbs } from 'interfaces/BreadCrumbsInterface'
import Breadcrumbs from 'components/Breadcrumbs'

import {
  AuditDocument,
  useAddAuditActivityCommentMutation,
  useAuditQuery,
  useDeleteAuditActivityCommentMutation,
  useUpdateAuditActivityCommentMutation,
} from 'generated/graphql'
import SubmissionModal from 'components/SubmissionModal'
import convertDateToString from 'utils/convertDateToString'
import notify from 'utils/notify'
import { Notification } from 'constants/typeConstants'
import { CSVStringify } from 'utils/CsvStringify'
import downloadCsv from 'utils/downloadCsv'
import classNames from 'classnames'
import AddActivityCommentModal from './AddActivityCommentModal/AddActivityCommentModal'
import TableComponent from 'components/Table/Table'
import EditActivityCommentModal from './EditActivityCommentModal/EditActivityCommentModal'
import styles from './styles.module.scss'
import HeaderRowInfo from 'components/HeaderRowInfo/HeaderRowInfo'

interface ITableItem {
  createdAt: string
  comment: string
  user: any
}

const ActivityComments: FC = () => {
  const { auditId, auditActivityId } = useParams<{
    auditId: string
    auditActivityId: string
  }>()
  const breadcrumbs: IBreadcrumbs[] = [
    { name: 'Home', to: '/' },
    { name: 'My Audits', to: '/audits' },
    { name: 'Audit', to: `/audits/${auditId}` },
    { name: 'Activities', to: `/audits/${auditId}/activities` },
    { name: 'Activity Comments' },
  ]
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  })

  const tableColumns: any[] = [
    {
      title: 'Date',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (value: string, row: ITableItem) =>
        convertDateToString(row.createdAt),
      transform: (col: Record<string, any>): string => col.createdAt,
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: (value: string, row: ITableItem) =>
        row.user.firstName + ' ' + row.user.lastName,
      transform: (col: Record<string, any>): string =>
        col.user.firstName + ' ' + col.user.lastName,
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      render: (value: string, row: ITableItem) => row.comment,
      transform: (col: Record<string, any>): string => col.comment,
    },
    {
      title: '',
      dataIndex: '',
      key: 'editable',
      render: (value: string, row: ITableItem) => {
        return (
          <div>
            <Button
              onClick={() => {
                getSelectedComment(row)
              }}
              containerStyle={'link'}
              colorType={'primary'}
              size="sm"
              className={styles.button}
            >
              <Icon name="edit" prefix="fas" />
            </Button>
            <span className={styles.separator}>|</span>
            <Button
              onClick={() => {
                handleDelete(row)
              }}
              containerStyle={'link'}
              colorType={'danger'}
              size="sm"
              className={styles.button}
            >
              <Icon name="trash" prefix="fas" />
            </Button>
          </div>
        )
      },
      transform: (col: Record<string, any>): string => col.comment,
      notForCSV: true,
    },
  ]

  const [showCreationModal, setShowCreationModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showSubmissionModal, setShowSubmissionModal] = useState(false)
  const [addActivityComment] = useAddAuditActivityCommentMutation()
  const [updateActivityComment] = useUpdateAuditActivityCommentMutation()
  const [deleteActivityComment] = useDeleteAuditActivityCommentMutation()
  const [selectedComment, setSelectedComment] = useState<any>(null)
  const toggleCreationModal = useCallback(() => {
    setShowCreationModal((show) => !show)
  }, [])
  const toggleEditModal = useCallback(() => {
    setShowEditModal((show) => !show)
  }, [])

  const toggleSubmissionModal = useCallback(() => {
    setShowSubmissionModal((show) => !show)
  }, [])

  const getSelectedComment = (comment: any) => {
    setSelectedComment(comment)
    toggleEditModal()
  }

  const { data: auditData } = useAuditQuery({
    variables: { input: auditId! },
  })

  const activities = auditData?.audit?.activities ?? []
  const comments = activities.find((a) => a?.id === auditActivityId)?.comments
  const activeActivity = activities.find((act) => act?.id === auditActivityId)

  const AddAuditActivityComment = (data: { comment: string }) => {
    const input = {
      auditActivityId,
      comment: data.comment,
    }
    addActivityComment({
      variables: { input },
      refetchQueries: [{ query: AuditDocument, variables: { input: auditId } }],
    })
      .then(() => {
        mounted.current && toggleCreationModal()
        notify(Notification.SUCCESS, 'Your comment was added!')
      })
      .catch(() => {
        notify(
          Notification.ERROR,
          'There is some error while adding new comment'
        )
      })
  }

  const updateAuditActivityComment = (data: any) => {
    const input = {
      id: selectedComment?.id,
      comment: data.comment,
    }
    updateActivityComment({
      variables: { input },
      refetchQueries: [{ query: AuditDocument, variables: { input: auditId } }],
    })
      .then(() => {
        mounted.current && toggleEditModal()
        notify(Notification.SUCCESS, 'Your activity was saved!')
      })
      .catch(() => {
        notify(
          Notification.ERROR,
          'There is some error while updating audit activity'
        )
      })
  }

  const handleDelete = (comment: any) => {
    console.log(comment)
    toggleSubmissionModal()
    setSelectedComment(comment)
  }

  const deleteAuditActivityComment = () => {
    deleteActivityComment({
      variables: { id: selectedComment.id },
      refetchQueries: [{ query: AuditDocument, variables: { input: auditId } }],
    })
      .then(() => {
        toggleSubmissionModal()
        notify(Notification.SUCCESS, 'Activity was deleted!')
      })
      .catch(() => {
        notify(
          Notification.ERROR,
          'There is some error while deleting audit activity'
        )
      })
  }

  const downloadCsvFile = async () => {
    console.log(comments)
    const data = comments ? comments : []
    const newColumns = tableColumns.filter((r: any) => !r.notForCSV)
    const csv = await CSVStringify(data as Array<Record<string, unknown>>, {
      header: true,
      columns: newColumns,
    })

    downloadCsv(csv, 'Comments.csv')
  }

  return (
    <>
      <AddActivityCommentModal
        show={showCreationModal}
        onClose={toggleCreationModal}
        onSubmit={AddAuditActivityComment}
        auditId={auditId}
        activity={activeActivity}
      ></AddActivityCommentModal>
      <EditActivityCommentModal
        show={showEditModal}
        onClose={toggleEditModal}
        onSubmit={updateAuditActivityComment}
        activity={activeActivity}
        comment={selectedComment}
      ></EditActivityCommentModal>
      <SubmissionModal
        name="comment"
        show={showSubmissionModal}
        onClose={toggleSubmissionModal}
        onSubmit={deleteAuditActivityComment}
      />
      <header className={styles.header}>
        <div className="col-lg-5">
          <h2>Audit Activities Home</h2>
          <Breadcrumbs links={breadcrumbs} />
        </div>
        <HeaderRowInfo auditData={auditData} />
      </header>
      <div className={styles.container}>
        <div className="row">
          <h2 className="col-md-6">List of all Related Comments</h2>
          <div className={classNames('col-md-6', styles.align)}>
            <Button
              label="New Comment"
              onClick={toggleCreationModal}
              secondaryIcon="plus"
            ></Button>
          </div>
        </div>
        <div className={styles.align}>
          <Button
            label="Download CSV"
            containerStyle={'outline'}
            colorType={'info'}
            onClick={downloadCsvFile}
          ></Button>
        </div>
        <TableComponent columns={tableColumns} data={comments ?? []} />
      </div>
    </>
  )
}

export default ActivityComments

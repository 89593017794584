import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Icon } from '@wmgtech/legato'

import FormSelect from 'components/Form/FormSelect'
import LoadingButton from 'components/LoadingButton/LoadingButton'
import { OfferStatusesQuery, useOfferStatusesQuery } from 'generated/graphql'
import useData from 'hooks/useData'
import convertToList from 'utils/convertToList'
import styles from './styles.module.scss'

interface IProps {
  defaultValues: {
    id: string
    statusId: string
  }
  onSubmit: (data: { id: string; statusId: string }) => void
}

const OfferStatusForm: FC<IProps> = ({ onSubmit, defaultValues }) => {
  const { data, loading, previousData } = useOfferStatusesQuery()
  const statusesList = useData<OfferStatusesQuery>({
    loading,
    previousData,
    data,
  })
  const statuses = convertToList(statusesList?.offerStatuses)

  const methods = useForm({ defaultValues: defaultValues })

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        autoComplete="off"
        className={styles.container}
      >
        <input hidden name="id" />
        <FormSelect name="statusId" list={statuses || []} />
        <LoadingButton
          type="submit"
          containerStyle={'link'}
          size="md"
          className={styles.button}
        >
          <Icon name="floppy-disk" prefix="fas" />
        </LoadingButton>
      </form>
    </FormProvider>
  )
}

export default OfferStatusForm

import { FC } from 'react'
import { Icon, Modal } from '@wmgtech/legato'

import ActivityCommentForm from '../ActivityCommentForm'
import styles from './styles.module.scss'

interface ICommentProps {
  show: boolean
  activity: any
  comment: any
  onClose: () => void
  onSubmit: (data: any) => void
}

const EditActivityCommentModal: FC<ICommentProps> = ({
  show,
  activity,
  comment,
  onClose,
  onSubmit,
}) => {
  const defaultValues = {
    comment: comment?.comment,
  }

  return (
    <Modal
      className={styles.modal}
      isOpen={show}
      title={
        <div className={styles['modal-title']}>
          <Icon className={styles['modal-title-icon']} name="comment" />
          <h2>Update Comment</h2>
          <hr className="divider" />
          <span>Date saved: {}</span>
        </div>
      }
      onClose={onClose}
      shouldCloseOnOverlayClick
    >
      <ActivityCommentForm
        defaultValues={defaultValues}
        activity={activity}
        onClose={onClose}
        onSubmit={onSubmit}
      ></ActivityCommentForm>
    </Modal>
  )
}

export default EditActivityCommentModal

import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { AuditorsListDocument, useCreateAuditorMutation } from 'generated/graphql';
import styles from './styles.module.scss'
import notify from 'utils/notify'
import { Notification } from 'constants/typeConstants'
import AuditorForm from '../AuditorForm'

const CreateAuditor: FC = () => {
  const history = useHistory()

  const [createAuditor, { loading }] = useCreateAuditorMutation()

  const createAuditorFunc = (data: any) => {
    createAuditor({
      variables: { input: data },
      refetchQueries:[{
        query: AuditorsListDocument
      }]
    }).then(() => history.push('/auditors'))
      .catch(() => notify(Notification.ERROR, 'There is some error while creating the auditor'))
  }

  const defaultValues = {
    name: ''
  }

  return (
    <div>
      <header className={styles.header}>
        <h2>New Auditor {loading}</h2>
      </header>
      <AuditorForm
        action={'create'}
        defaultValues={defaultValues}
        onSubmit={createAuditorFunc}
        loading={loading}
      />
    </div>
  )
}

export default CreateAuditor

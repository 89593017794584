import React, { FC } from 'react'
import Breadcrumbs from '../../components/Breadcrumbs'
import { IBreadcrumbs } from '../../interfaces/BreadCrumbsInterface'
import {
  ArtistsListQuery,
  AuditorsListQuery,
  GetActivityTypesQuery,
  LabelsListQuery,
  useArtistsListQuery,
  useAuditorsListQuery, useGetActivityTypesQuery,
  useLabelsListQuery,
} from '../../generated/graphql'
import useData from '../../hooks/useData'
import AdminControlCard from 'components/AdminControlCard/AdminControlCard'
import styles from './styles.module.scss'

const AdminControls: FC = () => {
  const {
    loading: labelsLoading,
    previousData: labelsPreviousData,
    data: labelsData,
  } = useLabelsListQuery()
  const labelListData = useData<LabelsListQuery>({
    loading: labelsLoading,
    previousData: labelsPreviousData,
    data: labelsData,
  })

  const {
    loading: auditorsLoading,
    previousData: auditorsPreviousData,
    data: auditorsData,
  } = useAuditorsListQuery()
  const auditorListData = useData<AuditorsListQuery>({
    loading: auditorsLoading,
    previousData: auditorsPreviousData,
    data: auditorsData,
  })

  const {
    loading: artistsLoading,
    previousData: artistsPreviousData,
    data: artistsData,
  } = useArtistsListQuery()
  const artistListData = useData<ArtistsListQuery>({
    loading: artistsLoading,
    previousData: artistsPreviousData,
    data: artistsData,
  })

  const {
    loading: activityTypesLoading,
    previousData: activityTypesPreviousData,
    data: activityTypesData,
  } = useGetActivityTypesQuery()
  const activityTypesListData = useData<GetActivityTypesQuery>({
    loading: activityTypesLoading,
    previousData: activityTypesPreviousData,
    data: activityTypesData,
  })

  const breadcrumbs: IBreadcrumbs[] = [
    { name: 'Home', to: '/' },
    { name: 'Admin Controls' },
  ]

  return (
    <div>
      <header className={styles.header}>
        <h2>Admin Controls</h2>
        <Breadcrumbs links={breadcrumbs} />
      </header>

      <div>
        <div className="row">
          <div className="col-md-4 mb-2">
            <AdminControlCard
              link="/labels"
              title="Labels"
              loading={labelsLoading}
              total={labelListData?.labels?.total || 'N/A'}
              icon="music"
            />
          </div>
          <div className="col-md-4 mb-2">
            <AdminControlCard
              link="/auditors"
              title="External Auditors"
              loading={auditorsLoading}
              total={auditorListData?.auditors?.total || 'N/A'}
              icon="pencil"
            />
          </div>
          <div className="col-md-4 mb-2">
            <AdminControlCard
              link="/artists"
              title="Artists"
              loading={artistsLoading}
              total={artistListData?.artists?.total || 'N/A'}
              icon="microphone"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-2">
            <AdminControlCard
              link="/rates"
              title="Rates"
              total={null}
              icon="percent"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-2">
            <AdminControlCard
              link="/activity-types"
              title="Activity Types List"
              loading={activityTypesLoading}
              total={activityTypesListData?.auditActivityTypes?.total || 'N/A'}
              icon="calendar"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminControls

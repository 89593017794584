import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button } from '@wmgtech/legato'
import classNames from 'classnames'

import HeaderRowInfo from 'components/HeaderRowInfo'
import Breadcrumbs from 'components/Breadcrumbs'
import {
  ClaimsRevisionsQuery,
  useAuditQuery,
  useClaimsRevisionsQuery,
  useCreateAuditClaimsRevisionMutation,
  useDeleteAuditClaimsRevisionMutation,
  useLastAuditReservedAmountsQuery,
  useUpdateAuditClaimsRevisionMutation,
} from 'generated/graphql'
import { IBreadcrumbs } from 'interfaces/BreadCrumbsInterface'
import RevisionTabs from './RevisionsTabs'
import AddRevisionModal from '../AddRevisionModal'
import SubmissionModal from 'components/SubmissionModal'
import convertDateToString from 'utils/convertDateToString'
import notify from 'utils/notify'
import { Notification } from 'constants/typeConstants'
import useData from 'hooks/useData'
import Spinner from 'components/Spinner'
import styles from './styles.module.scss'

const Revisions: FC<any> = () => {
  const { auditId } = useParams<{ auditId: string }>()

  const breadcrumbs: IBreadcrumbs[] = [
    { name: 'Home', to: '/' },
    { name: 'My Audits', to: '/audits' },
    { name: 'Audit', to: `/audits/${auditId}` },
    { name: 'Revisions' },
  ]

  const { loading: dataLoading, data: auditData } = useAuditQuery({
    variables: { input: auditId! },
  })

  const { loading, previousData, data, refetch } = useClaimsRevisionsQuery({
    variables: { id: auditId },
  })
  const revisionsData = useData<ClaimsRevisionsQuery>({
    loading,
    previousData,
    data,
  })

  const revisionsList = revisionsData?.auditClaimsRevisions

  const { data: reserves, loading: reservesLoading } =
    useLastAuditReservedAmountsQuery({
      variables: { auditId },
      fetchPolicy: 'no-cache',
    })

  const mounted = useRef(false)

  useEffect(() => {
    setActiveRevision(revisionsList?.[0]?.id)
  }, [revisionsList])

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  })

  const [showRevisionModal, setShowRevisionModal] = useState(false)
  const [showSubmissionModal, setShowSubmissionModal] = useState(false)
  const [activeRevisionId, setActiveRevision] = useState<any>()

  const [createAuditRevision] = useCreateAuditClaimsRevisionMutation()
  const [updateAuditRevision] = useUpdateAuditClaimsRevisionMutation()
  const [deleteAuditRevision] = useDeleteAuditClaimsRevisionMutation()

  const toggleRevisionModal = useCallback(() => {
    setShowRevisionModal((show) => !show)
  }, [])

  const toggleSubmissionModal = useCallback(() => {
    setShowSubmissionModal((show) => !show)
  }, [])

  const createRevision = (data: any) => {
    const input = {
      ...data,
      revisionDate: convertDateToString(data.revisionDate),
      auditId,
    }

    createAuditRevision({
      variables: { input },
      onCompleted() {
        refetch()
        mounted.current && toggleRevisionModal()
        notify(Notification.SUCCESS, 'Your revision was created!')
      },
      onError() {
        notify(
          Notification.ERROR,
          'There is some error while creating revision'
        )
      },
    })
  }

  const updateRevision = (data: any) => {
    const input = {
      ...data,
      revisionDate: convertDateToString(data.revisionDate),
    }
    updateAuditRevision({
      variables: { input },
      onCompleted() {
        refetch()
        notify(Notification.SUCCESS, 'Your revision was updated!')
      },
      onError() {
        notify(
          Notification.ERROR,
          'There is some error while updating revision'
        )
      },
    })
  }

  const deleteRevision = () => {
    deleteAuditRevision({
      variables: { id: activeRevisionId },
      onCompleted() {
        refetch()
        mounted.current && toggleSubmissionModal()
        notify(Notification.SUCCESS, 'Your revision was deleted!')
        window.location.reload()
      },
      onError() {
        notify(
          Notification.ERROR,
          'There is some error while deleting revision'
        )
      },
    })
  }

  const changeActiveRevision = (id: any) => {
    setActiveRevision(id)
  }

  return (
    <>
      <AddRevisionModal
        onSubmit={createRevision}
        onClose={toggleRevisionModal}
        show={showRevisionModal}
      />
      <SubmissionModal
        name="revision "
        show={showSubmissionModal}
        onClose={toggleSubmissionModal}
        onSubmit={deleteRevision}
      />
      <header className={styles.header}>
        <div className="col-lg-5">
          <h2>Revisions</h2>
          <Breadcrumbs links={breadcrumbs} />
        </div>
        <HeaderRowInfo auditData={auditData} />
      </header>
      <div className={styles.container}>
        <div className="row">
          <h2 className="col-md-6">Claims Revision Allocations</h2>
        </div>
        <div className="divider"></div>
        <div className="row">
          <div className="col-md-6">
            Below are all the revisions creted for the audit
          </div>
          <div className={classNames(styles['button-wrapper'], 'col-md-6')}>
            <Button
              onClick={toggleRevisionModal}
              colorType="success"
              secondaryIcon="plus"
              label="Add Revision"
            />
            <Button
              onClick={toggleSubmissionModal}
              colorType="danger"
              secondaryIcon="trash"
            />
          </div>
        </div>
        {loading || dataLoading || reservesLoading ? (
          <p>
            <Spinner name="circle-notch" />
          </p>
        ) : revisionsList?.length ? (
          <RevisionTabs
            revisions={revisionsList ?? []}
            onChangeTab={changeActiveRevision}
            onUpdateRevision={updateRevision}
            auditData={auditData}
            reserves={reserves}
          />
        ) : (
          <div>No revisions exist for this audit</div>
        )}
      </div>
    </>
  )
}

export default Revisions

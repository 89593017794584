import { FC, ReactNode, useState } from 'react'
import { IIconProps, Icon, Tabs } from '@wmgtech/legato'

import Overview from '../AuditOverview'
import AuditLabels from '../AuditLabels'
import Reserves from '../Reserves'
import Claims from '../Claims'
import Offers from '../Offers'
import AuditActivities from '../AuditActivities'
import styles from './styles.module.scss'

interface ITab {
  id: string
  title: string | any
  element?: ReactNode
}

const getTitle = (title: string, icon: IIconProps['name']) => {
  return (
    <div className={styles.tabs}>
      <Icon name={icon} className={styles.icon} prefix="fas" />
      {title}
    </div>
  )
}

const AuditTabs: FC<any> = ({ auditData }) => {
  const tabList: Array<ITab> = [
    {
      id: '1',
      title: getTitle('Overview', 'chart-area'),
      element: <Overview />,
    },
    {
      id: '2',
      title: getTitle('Labels', 'music'),
      element: <AuditLabels />,
    },
    {
      id: '3',
      title: getTitle('Reserves', 'university'),
      element: <Reserves auditData={auditData} />,
    },
    {
      id: '4',
      title: getTitle('Claims', 'dollar-sign'),
      element: <Claims auditData={auditData} />,
    },
    {
      id: '5',
      title: getTitle('Offers', 'money-bill'),
      element: <Offers auditData={auditData} />,
    },
    {
      id: '6',
      title: getTitle('Activities', 'calendar'),
      element: <AuditActivities auditData={auditData} />,
    },
  ]
  const [tabContent, setTabContent] = useState(tabList[0].element)

  const TabSelect = (item: ITab) => {
    setTabContent(item.element)
  }

  const renderTab = () => <div>{tabContent}</div>

  return (
    <>
      <Tabs
        list={tabList}
        mode="in-page"
        initialTabId="1"
        onClick={TabSelect}
      />
      {renderTab()}
    </>
  )
}

export default AuditTabs

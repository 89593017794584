import { FC } from 'react'
import { Link } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import FormFooter from 'components/Form/FormFooter'
import FormInputValidated from 'components/Form/FormInputValidated'
import { MutationCreateAuditActivityArgs, MutationUpdateAuditActivityArgs } from '../../../generated/graphql';
import { Input, InputGroup } from '@wmgtech/legato';
import styles from '../../Audit/AuditForm/styles.module.scss';
import FormSelect from '../../../components/Form/FormSelect';
import { IListItem } from '../../../components/Form/FormSelect/FormSelect';

interface IActivityTypeForm {
  defaultValues: any
  action: string
  onSubmit: (data: MutationCreateAuditActivityArgs | MutationUpdateAuditActivityArgs) => void
  loading: boolean
}

const ActivityTypeForm: FC<IActivityTypeForm> = ({ action, defaultValues, onSubmit, loading }) => {
  const methods = useForm({
    defaultValues: defaultValues,
  })

  const beforeSubmit = (data: any) => {
    onSubmit({ ...data, order: Number(data.order) })
  }

  const responsibilities: IListItem[] = [
    { title: 'RMRA', key: 'RMRA' },
    { title: 'Auditor', key: 'Auditor' }
  ]

  const categories: IListItem[] = [
    { title: 'NOI', key: 'NOI' },
    { title: 'Fieldwork', key: 'Fieldwork' }
  ]

  const auditStatuses: IListItem[] = [
    { title: 'Status 1', key: 'Status 1' },
    { title: 'Status 2', key: 'Status 2' }
  ]

  const reportCategories: IListItem[] = [
    { title: 'Report 1', key: 'Report 1' },
    { title: 'Report 2', key: 'Report 2' }
  ]

  const { register } = methods

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(beforeSubmit)} autoComplete="off">
        <div className='form-group form-group-three'>
          <FormInputValidated
            defaultValue={defaultValues?.name}
            name="name"
            label="Activity Type Name"
            placeholder="Name must be unique"
            message="Activity Type Name can't be blank"
            rules={{ required: true }}
          ></FormInputValidated>
          <FormInputValidated
            defaultValue={defaultValues?.name}
            name="activityNumber"
            label="Activity Number"
            placeholder="Activity Number"
            message="Activity Number can't be blank"
            rules={{ required: true }}
          ></FormInputValidated>
          <FormInputValidated
            defaultValue={defaultValues?.name}
            name="statusNumber"
            label="Status Number"
            placeholder="Status Number"
            message="Status Number can't be blank"
            rules={{ required: true }}
          ></FormInputValidated>
          <FormInputValidated
            defaultValue={defaultValues?.name}
            name="order"
            label="Activity Order"
            placeholder="Activity Order"
            message="Activity Order can't be blank"
            rules={{ required: true }}
          ></FormInputValidated>
          <InputGroup className={styles['input-group']} label="Responsibility">
            <FormSelect
              name="responsibility"
              placeholder="Responsibility"
              list={responsibilities}
            ></FormSelect>
          </InputGroup>
        </div>
        <div className='form-group form-group-three'>
          <Input
            {...register('description')}
            defaultValue={defaultValues?.description}
            name="description"
            placeholder="Description of Activity Type"
          />
        </div>
        <div className='form-group form-group-three'>
          <InputGroup className={styles['input-group']} label="Category">
            <FormSelect
              name="category"
              placeholder="Select Category"
              list={categories}
            ></FormSelect>
          </InputGroup>
          <InputGroup className={styles['input-group']} label="Audit Status">
            <FormSelect
              name="auditStatus"
              placeholder="Select Audit Status"
              list={auditStatuses}
            ></FormSelect>
          </InputGroup>
          <InputGroup className={styles['input-group']} label="Optional Report Category">
            <FormSelect
              name="reportCategory"
              placeholder="Select Report Category"
              list={reportCategories}
            ></FormSelect>
          </InputGroup>
        </div>
        <footer>
          <FormFooter
            cancel={<Link to={'/activity-types'}>Cancel</Link>}
            submit={action}
            loading={loading}
          />
        </footer>
      </form>
    </FormProvider>
  )
}

export default ActivityTypeForm

import { FC, useCallback, useState } from 'react'
import { Button, Icon } from '@wmgtech/legato'
import { Link, useParams } from 'react-router-dom'

import Table from 'components/Table'
import AddAuditLabelModal from './AddAuditLabelModal'
import {
  AuditDocument,
  AuditQuery,
  useAddAuditLabelMutation,
  useAuditQuery,
  useRemoveAuditLabelMutation,
} from 'generated/graphql'
import notify from 'utils/notify'
import { Notification } from 'constants/typeConstants'
import styles from './styles.module.scss'
import useData from 'hooks/useData'
import currencyFormat from 'utils/currencyFormat'
import SubmissionModal from 'components/SubmissionModal'

const AuditLabels: FC = () => {
  const { id } = useParams<{ id?: string }>()

  const columns = [
    {
      title: 'Label Name',
      dataIndex: 'name',
      key: 'name',
      sortable: true,
    },
    {
      title: 'Artist Earnings',
      dataIndex: 'earnings',
      key: 'earnings',
      sortable: true,
      render: (value: string, row: any) => currencyFormat(row.earnings),
    },
    {
      title: 'Current Reserve',
      dataIndex: 'earnings', //TODO replace with reserve
      key: 'reserve',
      sortable: true,
      render: (value: string, row: any) => currencyFormat(row.earnings),
    },
    {
      title: 'Current Claims',
      dataIndex: 'earnings', //TODO replace with claims
      key: 'claims',
      sortable: true,
      render: (value: string, row: any) => currencyFormat(row.earnings),
    },
    {
      title: 'Current Offers',
      dataIndex: 'earnings', //TODO replace with offers
      key: 'offers',
      sortable: true,
      render: (value: string, row: any) => currencyFormat(row.earnings),
    },
    {
      title: '',
      dataIndex: '',
      key: 'editable',
      render: (value: string, row: any, index: any) => {
        //if (labels.length - 1 === index) return
        return (
          <div>
            <Link to={`/audits/${id}/labels/${row.labelId}/edit`}>
              <Icon name="edit" prefix="fas" />
            </Link>
            <span className={styles.separator}>|</span>
            <Button
              onClick={() => {
                setAuditLabel(row.id)
                toggleSubmissionModal()
              }}
              containerStyle={'link'}
              colorType={'danger'}
              size="sm"
              className={styles.button}
            >
              <Icon name="trash" prefix="fas" />
            </Button>
          </div>
        )
      },
    },
  ]

  const [showChangePeriodModal, setShowChangePeriodModal] = useState(false)
  const [showSubmissionModal, setShowSubmissionModal] = useState(false)
  const [addLabel] = useAddAuditLabelMutation()
  const [removeLabel] = useRemoveAuditLabelMutation()
  const [auditLabelId, setAuditLabel] = useState('')

  const toggleCreationModal = useCallback(() => {
    setShowChangePeriodModal((show) => !show)
  }, [])

  const toggleSubmissionModal = useCallback(() => {
    setShowSubmissionModal((show) => !show)
  }, [])

  const removeAuditLabel = () => {
    removeLabel({
      variables: { id: auditLabelId },
      refetchQueries: [
        {
          query: AuditDocument,
          variables: { input: id },
        },
      ],
    })
      .then(() => {
        toggleSubmissionModal()
        notify(Notification.SUCCESS, 'You have deleted a label')
      })
      .catch(() => {
        notify(
          Notification.ERROR,
          'There is some error while deleting audit label'
        )
      })
  }

  const AddNewLabel = (data: any) => {
    addLabel({
      variables: { input: { ...data, auditId: id } },
      refetchQueries: [
        {
          query: AuditDocument,
          variables: { input: id },
        },
      ],
    })
      .then(() => {
        toggleCreationModal()
        notify(Notification.SUCCESS, 'Your label was added!')
      })
      .catch(() => {
        notify(
          Notification.ERROR,
          'There is some error while creating audit label'
        )
      })
  }

  const getAuditLabelsData = useAuditQuery({ variables: { input: id! } })

  const auditLabels = useData<AuditQuery>({
    loading: getAuditLabelsData.loading,
    previousData: getAuditLabelsData.previousData,
    data: getAuditLabelsData.data,
  })

  const labels = auditLabels?.audit?.labels
  //TODO make sure we need totals
  // labels.push({
  //   name: 'Totals:',
  //   id: 'totals',
  //   earnings: auditLabels?.audit?.labels
  //     ?.map((l) => l?.earnings)
  //     .reduce((acc: number, cur: any) => {
  //       return acc + parseFloat(cur)
  //     }, 0),
  // })

  return (
    <>
      <SubmissionModal
        name="label"
        show={showSubmissionModal}
        onClose={toggleSubmissionModal}
        onSubmit={removeAuditLabel}
      ></SubmissionModal>
      <AddAuditLabelModal
        show={showChangePeriodModal}
        onClose={toggleCreationModal}
        onSubmit={AddNewLabel}
        auditId={id}
      ></AddAuditLabelModal>
      <div className={styles.container}>
        <header className={styles.header}>
          <div>
            <h4>Quick View of Labels</h4>
            <p>
              Below are all the labels you have added to this audit - the artist
              earnings are for the audit period.
            </p>
          </div>
          <div className={styles['button-wrapper']}>
            <Button
              onClick={toggleCreationModal}
              secondaryIcon="plus"
              label="Add Label"
            />
          </div>
        </header>
        <div>
          <Table
            responsive
            fixedHeader
            columns={columns}
            data={labels || []}
            //totals
          />
        </div>
      </div>
    </>
  )
}

export default AuditLabels

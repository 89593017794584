import React, { FC, useCallback, useMemo, useState } from 'react'
import {
  useQueryParams,
  withDefault,
  StringParam,
  ArrayParam,
} from 'use-query-params'
import { uniq } from 'lodash'

import LoadingButton from 'components/LoadingButton'
import {
  useAuditCategoriesListQuery,
  useLabelsListQuery,
} from 'generated/graphql'
import ExecDashboardHeader from './components/ExecDashboardHeader'
import ButtonFilter from 'components/ButtonFilter'
//import PeriodStatistic from './components/PeriodStatistic'
import ChangeDatePeriodModal from './components/ChangeDatePeriodModal'
import PeriodFormData from './types/PeriodFormData'
import FlotChart from './components/FlotChart'
import CountByAudit from './components/CountByAudit'
import ActivityCompletion from './components/ActivityCompletion'
import AuditsTable from './components/AuditsTable'
import { QueryParamConfig } from 'serialize-query-params/lib/types'
import makeButtonFilterLabels from 'utils/makeButtonFilterLabels'
import makeActiveLabels from 'utils/makeActiveLabels'
//import calculateBalancePeriod from './utils/calculateBalancePeriod'
import makeYearsArray from './utils/makeYearsArray'
import ErrorAlert from 'components/ErrorAlert'
// import useData from 'hooks/useData'
// import useGlobalProgress from 'hooks/useGlobalProgress'
import notify from 'utils/notify'
import { Notification } from 'constants/typeConstants'
import MyDashboardCards from 'features/MyDashboardCards/MyDashboardCards'
//import BalanceSummary from './components/BalanceSummary/BalanceSummary'
import Spinner from 'components/Spinner/Spinner'
import styles from './styles.module.scss'

type QueryParams = {
  labelMasters: QueryParamConfig<(string | null)[]>
  categories: QueryParamConfig<(string | null)[]>
  perType: QueryParamConfig<string>
  sel: QueryParamConfig<string>
  yrSel: QueryParamConfig<string>
}

const quartersRange = [
  [1, 3],
  [4, 6],
  [7, 9],
  [10, 12],
]

const years = makeYearsArray()

const defaultPeriod = {
  perType: 'qtr',
  sel: '1',
  yrSel: years[0],
}

const ExecDashboard: FC = () => {
  const [showChangePeriodModal, setShowChangePeriodModal] = useState(false)
  const [query, setQuery] = useQueryParams<QueryParams>({
    labelMasters: withDefault(ArrayParam, []),
    categories: withDefault(ArrayParam, []),
    perType: withDefault(StringParam, defaultPeriod.perType),
    sel: withDefault(StringParam, defaultPeriod.sel),
    yrSel: withDefault(StringParam, defaultPeriod.yrSel),
  })
  const {
    data: masterListForFiltrationData,
    //loading: getLabelMasterListForFiltrationLoading,
    error: getLabelMasterListForFiltrationError,
  } = useLabelsListQuery({
    onError() {
      notify(Notification.ERROR, 'There is some error while loading labels')
    },
  })

  const {
    data: categoryListData,
    loading: getCategoryListLoading,
    error: getCategoryListError,
  } = useAuditCategoriesListQuery({
    onError() {
      notify(Notification.ERROR, 'There is some error while loading categories')
    },
  })

  const masterListForFiltrationLabels = useMemo(() => {
    const items = masterListForFiltrationData?.labels?.items

    if (items) {
      return makeButtonFilterLabels(items)
    }

    return null
  }, [masterListForFiltrationData?.labels?.items])

  const categoryListLabels = useMemo(() => {
    const items = categoryListData?.auditCategories

    if (items) {
      return makeButtonFilterLabels(items)
    }

    return null
  }, [categoryListData?.auditCategories])

  const balanceSummaryPeriod = useMemo(() => {
    const qtr = Number(query.sel) - 1

    return {
      year: Number(query.yrSel),
      fromMonth: Number(
        query.perType === 'mth' ? query.sel : quartersRange[qtr][0]
      ),
      toMonth: Number(
        query.perType === 'mth' ? query.sel : quartersRange[qtr][1]
      ),
    }
  }, [query.perType, query.sel, query.yrSel])

  // const reportSumByDetailNameListQuery = useGetReportSumByDetailNameListQuery({
  //   variables: {
  //     input: {
  //       categories: query.categories,
  //       labelMasters: query.labelMasters,
  //       period: balanceSummaryPeriod,
  //     },
  //   },
  //   onError() {
  //     notify(
  //       Notification.ERROR,
  //       'There is some error while loading the report sum'
  //     )
  //   },
  // })

  // const reportSumByDetailNameListData =
  //   useData<GetReportSumByDetailNameListQuery>({
  //     loading: reportSumByDetailNameListQuery.loading,
  //     previousData: reportSumByDetailNameListQuery.previousData,
  //     data: reportSumByDetailNameListQuery.data,
  //   })

  // const reportSumByDetailNameListItems = useMemo(() => {
  //   if (reportSumByDetailNameListData?.getReportSumByDetailNameList?.items) {
  //     return reportSumByDetailNameListData.getReportSumByDetailNameList.items.reduce<{
  //       [key: string]: number
  //     }>((acc, item) => {
  //       if (item && item.detailName) {
  //         return {
  //           ...acc,
  //           [item.detailName]: item.sum,
  //         }
  //       } else {
  //         return acc
  //       }
  //     }, {})
  //   }
  //   return null
  // }, [reportSumByDetailNameListData?.getReportSumByDetailNameList?.items])

  //const { fromDate, toDate } = calculateBalancePeriod(balanceSummaryPeriod)

  const handleCategoryClear = () => {
    setQuery({
      ...query,
      categories: [],
    })
  }

  const handleLabelsClear = () => {
    setQuery({
      ...query,
      labelMasters: [],
    })
  }

  const handleToggleModal = useCallback(() => {
    setShowChangePeriodModal((show) => !show)
  }, [])

  const handleChangePeriod = useCallback(
    (data: PeriodFormData) => {
      setQuery({
        ...query,
        perType: data.perType,
        sel: data.sel,
        yrSel: data.yrSel,
      })
      handleToggleModal()
    },
    [handleToggleModal, query, setQuery]
  )

  const handleLabelSelect = (id: string) => {
    const labelMasters = makeActiveLabels(query.labelMasters, id) as string[]

    setQuery({
      labelMasters,
    })
  }

  const handleCategorySelect = (id: string) => {
    const categories = makeActiveLabels([], id) as string[]

    setQuery({
      categories,
    })
  }

  // const periodStatisticSum = reportSumByDetailNameListItems
  //   ? reportSumByDetailNameListItems['PL Impact'] -
  //     reportSumByDetailNameListItems['Payment Amount']
  //   : null

  const labelMastersNames = useMemo(() => {
    if (masterListForFiltrationLabels) {
      return masterListForFiltrationLabels
        .filter((label) => query.labelMasters.includes(label.id))
        .map((label) => label.name)
    }

    return []
  }, [masterListForFiltrationLabels, query.labelMasters])

  // useGlobalProgress(
  //   reportSumByDetailNameListQuery.loading ||
  //     getLabelMasterListForFiltrationLoading ||
  //     getCategoryListLoading
  // )

  return (
    <>
      <ChangeDatePeriodModal
        years={years}
        show={showChangePeriodModal}
        onClose={handleToggleModal}
        onSubmit={handleChangePeriod}
        defaultValues={{
          perType: query.perType,
          sel: query.sel,
          yrSel: query.yrSel,
        }}
      />
      <ExecDashboardHeader
        labels={masterListForFiltrationLabels || []}
        activeLabels={query.labelMasters}
      />
      <hr />
      <div className={styles.container}>
        <div className={styles['align-center']}>
          {/* <BalanceSummary
            fromDate={fromDate}
            toDate={toDate}
            error={reportSumByDetailNameListQuery.error}
            items={reportSumByDetailNameListItems}
          /> */}
        </div>
        <div className={styles['align-center']}>
          {getLabelMasterListForFiltrationError && (
            <ErrorAlert>There are no labels</ErrorAlert>
          )}
          {masterListForFiltrationLabels && (
            <ButtonFilter
              title="Select a Label:"
              onClear={handleLabelsClear}
              onSelect={handleLabelSelect}
              labels={masterListForFiltrationLabels || []}
              activeLabels={uniq(query.labelMasters.map(Number))}
            />
          )}
          <hr style={{ margin: '20px' }} />
          {getCategoryListError && (
            <ErrorAlert>There are no categories</ErrorAlert>
          )}
          {categoryListLabels && (
            <ButtonFilter
              title="Select a Category:"
              onClear={handleCategoryClear}
              onSelect={handleCategorySelect}
              labels={categoryListLabels}
              activeLabels={uniq(query.categories.map(Number))}
            />
          )}
        </div>
        <div className={styles['align-center']}>
          {/* <PeriodStatistic
            onChangePeriod={handleToggleModal}
            perType={query.perType}
            sel={query.sel}
            year={query.yrSel}
            sum={periodStatisticSum}
            fromDate={fromDate}
            toDate={toDate}
          /> */}
          <FlotChart />
          <div className={styles['align-right']}>
            <LoadingButton
              onClick={() =>
                setQuery({
                  ...query,
                  ...defaultPeriod,
                })
              }
              size="xs"
              containerStyle={'outline'}
              colorType={'secondary-black'}
            >
              Default Period (QTD)
            </LoadingButton>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <MyDashboardCards
          categories={query.categories}
          labelMasters={query.labelMasters}
        />
      </div>
      <div className={styles.container}>
        <div>
          {!getCategoryListLoading && (
            <CountByAudit
              categories={query.categories}
              labelMasters={query.labelMasters}
              auditCategories={categoryListData?.auditCategories ?? []}
            />
          )}
          {getCategoryListLoading && <Spinner name="circle-notch"></Spinner>}
        </div>
        <ActivityCompletion
          categories={query.categories}
          labelMasters={query.labelMasters}
        />
      </div>
      <div className={styles.container}>
        <AuditsTable
          period={balanceSummaryPeriod}
          categories={query.categories}
          labelMasters={query.labelMasters}
          labelMastersNames={labelMastersNames}
        />
      </div>
    </>
  )
}

export default ExecDashboard

import React, { FC, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ArtistDocument, ArtistsListDocument, useArtistQuery, useUpdateArtistMutation } from 'generated/graphql'
import Spinner from 'components/Spinner'
import styles from './styles.module.scss'
import notify from 'utils/notify'
import { Notification } from 'constants/typeConstants'
import { isEmpty } from 'lodash'
import ArtistForm from '../ArtistForm'

const EditArtist: FC = () => {
  const [defaultValues, setDefaultValues] = useState({})
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const [updateArtist, { loading: updating }] = useUpdateArtistMutation()

  const { data, loading } = useArtistQuery({
    variables: { id: id! },
    onCompleted() {
      setDefaultValues({
        id: data?.artist?.id,
        name: data?.artist?.name
      })
    },
    onError() {
      notify(
        Notification.ERROR,
        'There is some error while loading the Artist data'
      )
    }
  })

  if (loading || isEmpty(defaultValues)) {
    return <Spinner name="circle-notch"/>
  }

  const updateArtistFunc = (data: any) => {
    updateArtist({
      variables: { input: { ...data, id } },
      update(cache: any, MutationResult: any) {
        cache.writeQuery({
          query: ArtistDocument,
          variables: {
            id
          },
          data: {
            artist: {
              ...MutationResult.data?.updateArtist
            }
          }
        })
      },
      refetchQueries:[{
        query: ArtistsListDocument
      }]
    }).then(() => history.push('/artists'))
      .catch(() => notify(Notification.ERROR, 'There is some error while updating the artist'))
  }

  return (
    <div>
      <header className={styles.header}>
        <h2>Artist: {data?.artist?.name}</h2>
      </header>
      <ArtistForm
        action={'update'}
        defaultValues={defaultValues}
        onSubmit={updateArtistFunc}
        loading={updating}
      />
    </div>
  )
}

export default EditArtist

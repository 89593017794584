import React, { FC, useCallback, useState } from 'react'
import { Table } from '@wmgtech/legato'
import { AuditDocument, useLabelQuery } from '../../../generated/graphql'
import { ColumnsType } from '@wmgtech/legato/build/components/Table/types'
import SubmissionModal from '../../../components/SubmissionModal/SubmissionModal'
import notify from '../../../utils/notify'
import { Notification } from '../../../constants/typeConstants'
import styles from './styles.module.scss'
import { useParams } from 'react-router-dom'
import Breadcrumbs from '../../../components/Breadcrumbs'
import { IBreadcrumbs } from '../../../interfaces/BreadCrumbsInterface'

interface ITableItem {
}

const LabelRates: FC = () => {
  const { id } = useParams<{ id?: string }>()

  const { data: labelData } = useLabelQuery({
    variables: { id: id! },
    onError() {
      notify(
        Notification.ERROR,
        'There is some error while loading the Audits data'
      )
    },
  })
  const [rateId] = useState('')
  const [showSubmissionModal, setShowSubmissionModal] = useState(false)
  const [deleteRate] = [] as any[]
  const { loading, refetch } = {} as any

  const toggleSubmissionModal = useCallback(() => {
    setShowSubmissionModal((show) => !show)
  }, [])

  const removeLabelRate = () => {
    deleteRate({
      variables: { id: rateId },
      refetchQueries: [
        {
          query: AuditDocument,
          variables: { input: rateId },
        },
      ],
    })
      .then(() => {
        refetch()
        toggleSubmissionModal()
        notify(Notification.SUCCESS, 'You have deleted a label')
      })
      .catch(() => {
        notify(
          Notification.ERROR,
          'There is some error while deleting audit label'
        )
      })
  }

  // const labels = labelListData?.labels?.items;

  const tableColumns: ColumnsType<any>[] = []
  const tableData: ITableItem[] = []

  const breadcrumbs: IBreadcrumbs[] = [
    { name: 'Home', to: '/' },
    { name: 'Admin Controls', to: '/home/admin_controls' },
    { name: 'Labels', to: '/labels' },
    { name: 'Label Rates' },
  ]

  return (
    <div>
      <header className={styles.header}>
        <h2>{labelData?.label?.name} Rates</h2>
        <Breadcrumbs links={breadcrumbs} />
      </header>
      <SubmissionModal
        name="label"
        show={showSubmissionModal}
        onClose={toggleSubmissionModal}
        onSubmit={removeLabelRate}
      />
      {
        loading
          ? <p>loading...</p>
          : <Table columns={tableColumns} data={tableData} striped="none"/>
      }
    </div>
  )
}

export default LabelRates

import { FC } from 'react'
import { Modal } from '@wmgtech/legato'

import LoadingButton from 'components/LoadingButton'
import styles from './styles.module.scss'

interface IModalProps {
  show: boolean
  onClose: () => void
  onSubmit: (data: any) => void
  count: number
}

const SuccessCreationModal: FC<IModalProps> = ({
  show,
  onClose,
  onSubmit,
  count,
}) => {
  return (
    <Modal
      className={styles.modal}
      isOpen={show}
      title={
        <div className={styles['modal-title']}>
          <h2>You've successfully created {count} new reserve(s)</h2>
        </div>
      }
      onClose={onClose}
      shouldCloseOnOverlayClick
    >
      <div className={styles.footer}>
        {onClose && (
          <LoadingButton
            containerStyle={'outline'}
            colorType={'secondary-black'}
            onClick={onClose}
          >
            Close
          </LoadingButton>
        )}
        <LoadingButton
          type="submit"
          containerStyle={'outline'}
          colorType={'primary'}
          onClick={onSubmit}
        >
          Download as CSV
        </LoadingButton>
      </div>
    </Modal>
  )
}

export default SuccessCreationModal

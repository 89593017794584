export enum Notification {
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
  ERROR = 'error'
}

export enum ReserveReason {
  DEFAULT_RATE = '1',
  CUSTOM_RATE = '2',
  CHANGE_AMOUNT = '3',
  TRANSFER_AMOUNT = '4',
  ADVANCE = '5',
  SETTLEMENT = '6'
}

export const RATE_CHANGE_REASON = [
  ReserveReason.DEFAULT_RATE,
  ReserveReason.CUSTOM_RATE,
]

export const OTHER_REASON = [
  ReserveReason.ADVANCE,
  ReserveReason.TRANSFER_AMOUNT,
  ReserveReason.CHANGE_AMOUNT,
  ReserveReason.SETTLEMENT,
]

export enum AuditStatuses {
  CURRENT = '1',
  SETTLED = '2',
  DORMANT = '3',
  INACTIVE = '4',
  OPEN = '5',
  CLOSED = '6'
}

export const CLOSED_AUDITS_STATUSES = [
  AuditStatuses.SETTLED,
  AuditStatuses.DORMANT,
  AuditStatuses.INACTIVE,
  AuditStatuses.CLOSED
]

export enum OfferStatuses {
  OPEN = '1',
  ADVANCE = '2',
  SETTLED = '3'
}

export enum Categories {
  UNION_AUDIT = '1',
  ROYALTY_AUDIT = '2',
  PUBLISHING_AUDIT = '3',
  PARTICIPATIONS_AUDIT = '4'
}

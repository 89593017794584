import { FC, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { Icon, Input, InputGroup, Textarea } from '@wmgtech/legato'
import FormSelect from 'components/Form/FormSelect/FormSelect'
import FormDatepicker from 'components/Form/FormDatepicker/FormDatepicker'
import LoadingButton from 'components/LoadingButton/LoadingButton'
import {
  ReservedAmountReasonsQuery,
  ReservedAmountsHistoryQuery,
  useDeafultRateQuery,
  useReservedAmountReasonsQuery,
  useReservedAmountsHistoryQuery,
} from 'generated/graphql'
import useData from 'hooks/useData'
import convertToList from 'utils/convertToList'
import FormCondition from 'components/Form/FormCondition'
import {
  ReserveReason,
  OTHER_REASON,
  RATE_CHANGE_REASON,
  Notification,
} from 'constants/typeConstants'
import { reserveCalculations } from 'utils/reserveCalculations'
import notify from 'utils/notify'
import { IReserveForm } from 'interfaces/ReserveFormInterface'
import styles from './styles.module.scss'
import convertDateToString from 'utils/convertDateToString'

interface IReserveFormProps {
  onSubmit: (data: IReserveForm) => void
  defaultValues?: any
  labelId?: string
  auditSubcategoryId?: string | null
  ramount?: string | null
}

const INITIAL_AMOUNT = '0.00'

const ReserveForm: FC<IReserveFormProps> = ({
  onSubmit,
  defaultValues,
  labelId,
  auditSubcategoryId,
  ramount,
}) => {
  const [rateCalc, setRateCalc] = useState({
    earnings: '0',
    fpp: '0',
    otherAmount: '0',
    rate: '0',
    additionalAmount: '0',
  })
  const {
    labelId: auditLabelId,
    auditTypeId,
    ramountId,
  } = useParams<{
    labelId: string
    auditTypeId: string
    ramountId: string
  }>()
  const [prevAmount, setPrevAmount] = useState(INITIAL_AMOUNT)
  const [defaultRate, setDefaultRate] = useState('0')

  const getReserveReasons = useReservedAmountReasonsQuery({
    onCompleted(response) {
      if (!ramountId) {
        const reasonId = response?.reservedAmountReasons![0]!.id || '1'
        setValue('reasonId', reasonId)
      }
    },
  })
  const ReserveReasonsData = useData<ReservedAmountReasonsQuery>({
    loading: getReserveReasons.loading,
    previousData: getReserveReasons.previousData,
    data: getReserveReasons.data,
  })

  const reasonsList = convertToList(ReserveReasonsData?.reservedAmountReasons)

  useDeafultRateQuery({
    variables: {
      auditSubcategoryId: auditSubcategoryId || '',
      labelId: labelId || '',
    },
    onCompleted(response) {
      const defaultRate = response?.defaultRate?.rate || '0'
      setValue('rate', defaultRate)
      setDefaultRate(defaultRate)
    },
  })

  const { data, loading, previousData } = useReservedAmountsHistoryQuery({
    variables: { auditLabelId, auditTypeId },
    onError() {
      notify(
        Notification.ERROR,
        'There is some error while loading the Reserves data'
      )
    },
    fetchPolicy: 'cache-and-network',
  })

  const reservesData = useData<ReservedAmountsHistoryQuery>({
    loading,
    previousData,
    data,
  })

  const reservedAmountsList = useMemo(() => {
    const reserves = reservesData?.reservedAmountsHistory
    const newList: any[] = []
    if (reserves?.length) {
      for (let i = 0; i < reserves.length; i++) {
        newList.push({ ...reserves[i] })
      }
    }
    newList?.sort((a, b) => (b?.reservedDate).localeCompare(a?.reservedDate))
    return newList
  }, [reservesData?.reservedAmountsHistory])

  const methods = useForm({
    defaultValues: defaultValues,
  })

  const { register, watch, getValues, setValue } = methods
  const watchReason = watch('reasonId')
  const watchDate = watch('reservedDate')

  useEffect(() => {
    const subscription = watch((data) => {
      const { earnings, rate, fpp, otherAmount, additionalAmount } = data
      setRateCalc({ earnings, rate, fpp, otherAmount, additionalAmount })
    })
    return () => subscription.unsubscribe()
  })

  const calculateResAmount = () => {
    return reserveCalculations(
      watchReason,
      rateCalc,
      getValues('previousAmount')
    )
  }

  useEffect(() => {
    if (reservedAmountsList?.length) {
      for (const reserve of reservedAmountsList) {
        const reserveDate = convertDateToString(getValues('reservedDate'))
        if (reserveDate && reserve?.reservedDate < reserveDate) {
          const previousReserveAmount = reserve?.amount ?? INITIAL_AMOUNT
          setPrevAmount(previousReserveAmount)
          if (!ramountId) {
            setValue('previousAmount', previousReserveAmount)
          }
          return
        } else {
          setPrevAmount(INITIAL_AMOUNT)
          if (!ramountId) {
            setValue('previousAmount', INITIAL_AMOUNT)
          }
        }
      }
    }
  }, [watchDate, getValues, setValue, reservedAmountsList, ramountId])

  const onReasonChange = (reason: string) => {
    methods.reset({
      ...defaultValues,
      reasonId: reason,
      reservedDate: watchDate,
    })
    if (ReserveReason.DEFAULT_RATE === reason) {
      setValue('rate', defaultRate)
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
        <header className={styles['form-header']}>
          <Icon name="info-circle" className="fa-2x"></Icon>
          <h4>General Information</h4>
        </header>
        <div className={styles.body}>
          <div className="form-group form-group-four">
            <FormDatepicker
              placeholder="Select a Date"
              name="reservedDate"
              label="Date"
              initialDate={defaultValues?.reservedDate}
              rules={{ required: true }}
              message="Reserve date can't be blank"
              disabled={!!ramountId}
            />
            <InputGroup label="Change Reason">
              <FormSelect
                name="reasonId"
                list={reasonsList ?? []}
                placeholder="Select a reason"
                onChange={onReasonChange}
                disabled={!!ramountId}
              />
            </InputGroup>
            <div></div>
          </div>
          <div className="dashes"></div>
          <div className={styles.calculation}>
            <header className={styles['form-header']}>
              <Icon name="calculator" className="fa-2x"></Icon>
              <h4>Reserve Calculation</h4>
            </header>
          </div>

          <FormCondition fieldName="reasonId" condition={OTHER_REASON}>
            <div>
              <div className="form-group form-group-five">
                <InputGroup label="Previous Reserve">
                  <div>${prevAmount}</div>
                  <input
                    {...register('previousAmount')}
                    name="previousAmount"
                    className={
                      (+getValues('previousAmount')).toFixed(2) === prevAmount
                        ? ''
                        : styles.mismatch
                    }
                    type={ramountId ? 'text' : 'hidden'}
                  />
                </InputGroup>
                <InputGroup label="Amount">
                  <Input
                    {...register('additionalAmount')}
                    name="additionalAmount"
                    type="number"
                    defaultValue={defaultValues?.additionalAmount}
                    step="any"
                  />
                </InputGroup>
                <InputGroup label="Reserve Amount">
                  <div>${ramountId ? ramount : calculateResAmount()}</div>
                </InputGroup>
                <div></div>
                <div></div>
              </div>
            </div>
          </FormCondition>

          <FormCondition fieldName="reasonId" condition={RATE_CHANGE_REASON}>
            <div className="form-group form-group-five">
              <InputGroup label="Earnings">
                <Input
                  {...register('earnings')}
                  name="earnings"
                  type="number"
                  step="any"
                />
              </InputGroup>
              <InputGroup
                label={
                  watchReason === ReserveReason.DEFAULT_RATE
                    ? 'Default Rate'
                    : 'Rate'
                }
              >
                <Input
                  {...register('rate')}
                  name="rate"
                  type="number"
                  disabled={watchReason === ReserveReason.DEFAULT_RATE}
                  step="any"
                />
              </InputGroup>
              <InputGroup label="FPP Amount">
                <Input
                  {...register('fpp')}
                  name="fpp"
                  type="number"
                  step="any"
                />
              </InputGroup>

              <InputGroup label="Other Amounts">
                <Input
                  {...register('otherAmount')}
                  name="otherAmount"
                  type="number"
                  step="any"
                />
              </InputGroup>
              <InputGroup label="Reserve Amount">
                <div>${ramountId ? ramount : calculateResAmount()}</div>
              </InputGroup>
            </div>
          </FormCondition>

          <div className="col-md-8">
            <label>Full Reserve Calculation:</label>
            {!calculateResAmount() && (
              <p>
                <b>Please enter values to calculateResAmount</b>
              </p>
            )}

            {!!calculateResAmount() && (
              <p>
                <FormCondition fieldName="reasonId" condition={OTHER_REASON}>
                  <strong>
                    $
                    {getValues('previousAmount')
                      ? getValues('previousAmount')
                      : INITIAL_AMOUNT}{' '}
                    + ${getValues('additionalAmount')}=$
                    {calculateResAmount()}
                  </strong>
                </FormCondition>

                <FormCondition
                  fieldName="reasonId"
                  condition={RATE_CHANGE_REASON}
                >
                  <strong>
                    (${getValues('earnings')} * {getValues('rate')}%) + $
                    {getValues('fpp')} + ${getValues('otherAmount')} = $
                    {calculateResAmount()}
                  </strong>
                </FormCondition>
              </p>
            )}
          </div>
          <div className="dashes"></div>
          <header className={styles['form-header']}>
            <Icon name="comment" className="fa-2x"></Icon>
            <h4> Explanation</h4>
          </header>
          <div className="form-group">
            <InputGroup label="Explanation">
              <Textarea
                {...register('notes')}
                name="notes"
                placeholder="Please provide an explanation for the change."
              />
            </InputGroup>
          </div>
        </div>
        <div className={styles.footer}>
          <LoadingButton type="submit" colorType={'primary'}>
            {ramountId ? 'Update Reserve' : 'Create Reserve'}
          </LoadingButton>
        </div>
      </form>
    </FormProvider>
  )
}

export default ReserveForm

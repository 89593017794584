import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import {
  GetActivityTypesDocument,
  useCreateActivityTypeMutation
} from 'generated/graphql';
import styles from './styles.module.scss'
import notify from 'utils/notify'
import { Notification } from 'constants/typeConstants'
import ActivityTypeForm from '../ActivityTypeForm';

const CreateActivityType: FC = () => {
  const history = useHistory()

  const [createActivityType, { loading }] = useCreateActivityTypeMutation()

  const createActivityTypeFunc = (data: any) => {
    createActivityType({
      variables: { input: data },
      refetchQueries: [{ query: GetActivityTypesDocument }]
    }).then(() => history.push('/activity-types'))
      .catch(() => notify(Notification.ERROR, 'There is some error while creating the activity type'))
  }

  const defaultValues = {
    name: '',
    description: '',
    category: null,
    reportCategory: null,
    responsibility: '',
    auditStatus: '',
    isStatus: true,
    isKpi: true,
    order: 1,
    activityNumber: '1',
    statusNumber: '1',
  }

  return (
    <div>
      <header className={styles.header}>
        <h2>New Activity Type</h2>
      </header>
      <ActivityTypeForm
        action={'create'}
        defaultValues={defaultValues}
        onSubmit={createActivityTypeFunc}
        loading={loading}
      />
    </div>
  )
}

export default CreateActivityType

import { FC } from 'react'
import { Input, InputGroup } from '@wmgtech/legato'
import { FormProvider, useForm } from 'react-hook-form'

import FormSelect from 'components/Form/FormSelect'
import LoadingButton from 'components/LoadingButton'
import useData from 'hooks/useData'
import {
  AuditSubcategoriesListQuery,
  LabelsListQuery,
  useAuditSubcategoriesListQuery,
  useLabelsListQuery,
} from 'generated/graphql'
import styles from './styles.module.scss'
import convertToList from 'utils/convertToList'
import MultiSelectWithTag from 'components/Form/MultiSelectWithTag/MultiSelectWithTag'

interface ILabelFormProps {
  onClose?: () => void
  onSubmit: (data: any) => void
}

const AddRateForm: FC<ILabelFormProps> = ({ onClose, onSubmit }) => {
  const getLabelList = useLabelsListQuery()
  const labelListData = useData<LabelsListQuery>({
    loading: getLabelList.loading,
    previousData: getLabelList.previousData,
    data: getLabelList.data,
  })

  const allLabels = labelListData?.labels?.items
  const labelList = convertToList(allLabels)

  const { loading, previousData, data } = useAuditSubcategoriesListQuery()
  const AuditSubcategoriesData = useData<AuditSubcategoriesListQuery>({
    loading,
    previousData,
    data,
  })

  const auditSubcategories = AuditSubcategoriesData?.auditCategories

  const subcategories = auditSubcategories?.reduce((acc: any, cur: any) => {
    return acc.concat(
      cur.subcategories.map((subcategory: any) => {
        return {
          id: subcategory.id,
          key: subcategory.id,
          title: subcategory.name,
        }
      })
    )
  }, [])

  const methods = useForm()
  const { register } = methods

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
          <div className={styles.body}>
            <InputGroup label="Audit Subcategory">
              <FormSelect
                name="auditSubcategoryId"
                list={subcategories || []}
                placeholder="Select a subcategory"
              />
            </InputGroup>
            <MultiSelectWithTag
              isClearable
              selectLabel="Labels"
              name="labelIds"
              list={labelList}
              placeholder="Select label(s)"
              validation={{
                required: true,
                message: "Labels array can't be blank",
              }}
            />
            <InputGroup label="Rate">
              <Input
                {...register('rate')}
                name="rate"
                placeholder="Rate"
                type="number"
                step="any"
              />
            </InputGroup>
          </div>
          <footer className={styles.footer}>
            {onClose && (
              <LoadingButton
                containerStyle={'outline'}
                colorType={'secondary-black'}
                onClick={onClose}
              >
                Cancel
              </LoadingButton>
            )}
            <LoadingButton
              type="submit"
              containerStyle={'outline'}
              colorType={'primary'}
            >
              Create rate
            </LoadingButton>
          </footer>
        </form>
      </FormProvider>
    </>
  )
}

export default AddRateForm

import { FC } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import ReserveForm from '../ReserveForm/ReserveForm'
import {
  AuditDocument,
  useAuditQuery,
  useCreateReservedAmountMutation,
} from 'generated/graphql'
import convertDateToString from 'utils/convertDateToString'
import notify from 'utils/notify'
import { Notification } from 'constants/typeConstants'
import Breadcrumbs from 'components/Breadcrumbs'
import {
  reserveCalculations,
  createRAmountObject,
} from 'utils/reserveCalculations'
import { IReserveForm } from 'interfaces/ReserveFormInterface'
import styles from './styles.module.scss'

interface IBreadcrumbs {
  name: string
  to?: string
}

const AddReserveCalc: FC = () => {
  const history = useHistory()
  const { auditId, labelId, auditTypeId } = useParams<{
    auditId: string
    labelId: string
    auditTypeId: string
  }>()
  const [createReserveAmount] = useCreateReservedAmountMutation()

  const { data: auditData } = useAuditQuery({
    variables: { input: auditId! },
    onError() {
      notify(
        Notification.ERROR,
        'There is some error while loading the Audits data'
      )
    },
  })

  const breadcrumbs: IBreadcrumbs[] = [
    { name: 'Home', to: '/' },
    { name: 'My Audits', to: '/audits' },
    { name: 'Audit', to: `/audits/${auditId}` },
    {
      name: 'Reserves',
      to: `/audits/${auditId}/types/${auditTypeId}/labels/${labelId}/ramounts`,
    },
    { name: 'Add Reserve' },
  ]

  const selectedLabel = auditData?.audit?.labels?.find(
    (label) => label?.id === labelId
  )

  const selectedSubcategory = auditData?.audit?.types?.find(
    (type) => type?.id === auditTypeId
  )

  const onSubmit = (data: IReserveForm) => {
    const amount =
      reserveCalculations(data.reasonId, data, data.previousAmount) || ''
    const ramount = createRAmountObject(data)

    const reservedDate = convertDateToString(data.reservedDate)
    const calculationDetails = JSON.stringify(ramount)

    const dataForCreation = {
      reservedDate,
      calculationDetails,
      auditLabelId: labelId,
      auditTypeId,
      amount,
      notes: data.notes || '',
      reasonId: data.reasonId,
    }

    createReserveAmount({
      variables: { input: dataForCreation },
      refetchQueries: [
        {
          query: AuditDocument,
          variables: { input: auditId },
        },
      ],
    })
      .then(() => {
        history.goBack()
        notify(Notification.SUCCESS, 'Reserve was successfully created')
      })
      .catch(() => {
        notify(Notification.ERROR, 'There is some error while creating reserve')
      })
  }

  const defaultValues = {
    additionalAmount: 0,
    earnings: 0,
    rate: 0,
    fpp: 0,
    otherAmount: 0,
    reservedDate: new Date(),
  }

  return (
    <>
      <header className={styles.header}>
        <div className="col-lg-5">
          <h2>Reserves</h2>
          <Breadcrumbs links={breadcrumbs} />
        </div>
      </header>
      <div className={styles.container}>
        <h3>
          Add New Reserve for {selectedLabel?.name} /{' '}
          {selectedSubcategory?.name}, audit {auditData?.audit?.reportName}{' '}
          (NOI:&nbsp;
          {auditData?.audit?.noticeDate || 'N/A'})
        </h3>
        <hr className="divider" />
        <ReserveForm
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          labelId={selectedLabel?.labelId}
          auditSubcategoryId={selectedSubcategory?.subcategoryId}
        />
      </div>
    </>
  )
}

export default AddReserveCalc
